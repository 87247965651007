import Link from "../../_core/components/Link";

export default (props) => {
	let expandSrc = props.expandSrc ? props.expandSrc : props.src;
	let encoded = btoa(expandSrc);
	return (
		<div className="Reel">
			<Link className="Reel-trigger" to={`/#/play/${encoded}/`}>
				Play
			</Link>
			<div className="Reel-teaser">
				<video
					autoPlay
					preload="true"
					muted
					loop
					controls={false}
					playsInline
					src={props.src}
				/>
			</div>
		</div>
	);
};
