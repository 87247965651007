import React, { Component } from "react";

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from "../_core/components/PageElement";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";
import SiteModifier from "../_core/components/SiteModifier";
import FadeIn from "../_core/components/FadeIn";
import Link from "../_core/components/Link";

// Models
import { Asset, Entry, Data, SetData } from "../_core/models";

// Project
import * as Project from "../components";

class Default extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	/*

		RENDER

	*/

	render() {
		let { entry } = this.props;
		if (!entry) return null;

		let blocks = entry.blocks || [];

		let isHome = entry.uri == "/";

		return (
			<Template
				modifiers={[entry.style, entry.type, entry.template]}
				setTitle={false}
				title={entry.title}
			>
				<FadeIn duration={0.4} delay={0.1} key={entry.id}>
					{/* Reset styles */}
					<SiteModifier auto demodifier={"scrolled"} />
					{(() => {
						if (isHome) {
							return <SiteModifier auto modifier={"home"} />;
						} else {
							return <SiteModifier auto demodifier={"home"} />;
						}
					})()}

					{/* Template */}

					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<Content>
								<h1>{entry.pageTitle || entry.title}</h1>
							</Content>
						</div>
					</div>

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks
								blocks={[...blocks]}
								entry={entry}
								modifiers={[]}
							/>
						</div>
					</div>
				</FadeIn>
			</Template>
		);
	}
}

export default Default;
