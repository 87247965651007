import React, { Component } from 'react';

// GA Event Logger
import GoogleAnalytics from 'react-ga';
import {Form} from '../../_core/components';

class Newsletter extends Component {


  render() {
    return (

          <Form
          target={'_blank'}
          modifier={'newsletter'}
          onSubmit={() => {

              GoogleAnalytics.event({
                  category: 'Subscriptions',
                  action: 'Subscription Submission'
              });

          }}
          ajax={false}
          method="post"
          action={'//gmail.us20.list-manage.com/subscribe/post?u=c32c165f6a3606325183b87c9&amp;id=92d8543ab6'}
          fields={[
        {
          name:'b_c32c165f6a3606325183b87c9_92d8543ab6',
          type:'text',
          modifier : 'hon',
          style:{position: 'absolute', left: '-5000px'}
        },
        {
          title : 'Sign up to our newsletter',
          name: 'EMAIL',
          type: 'email',
          placeholder: 'Enter Your Email Address'
        }
        ]}>
          </Form>
    );
  }
}

export default Newsletter;
