import React, { Component } from "react";

// Conf
import conf from "../_core/_conf";

// Core
import Loader from "../_core/components/Loader";
import Media from "../_core/components/Media";
import ViewportProgress from "../_core/components/ViewportProgress";
import JumpLink from "../_core/components/JumpLink";
import ScrollModifier from "../_core/components/ScrollModifier";
import ModifierSection from "../_core/components/ModifierSection";
import Image from "../_core/components/Image";
import Link from "../_core/components/Link";
import PageElement from "../_core/components/PageElement";
import Site from "../_core/components/Site";
import ScrollTo from "../_core/components/ScrollTo";
import Burger from "../_core/components/Burger";
import Icon from "../_core/components/Icon";
import Content from "../_core/components/Content";
import Transition from "../_core/components/Transition";
import SiteModifier from "../_core/components/SiteModifier";
import NormalizedScroller from "../_core/components/NormalizedScroller";
import MousePos from "../_core/components/MousePos";
import FadeIn from "../_core/components/FadeIn";
import Filter from "../_core/components/Filter";
import Html from "../_core/components/Html";

import { Entry, Asset, Data } from "../_core/models";
import { Template, Modal } from "../_core/controllers";
import { withStore, withSite } from "../_core/utils";

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import DefaultTemplate from "./default";
import BlogTemplate from "./blog";
import ListingTemplate from "./listing";
import LocationsTemplate from "./locations";
import PricingTemplate from "./pricing";

// Project
import * as Project from "../components";

function TemplateForModal({ entry }) {
  if (!entry) return null;
  let t = entry.template || null;

  switch (t) {
    case "locations":
      return <LocationsTemplate entry={entry} />;
      break;
    case "listing":
      return <ListingTemplate entry={entry} />;
      break;
    case "blog":
      return <BlogTemplate entry={entry} />;
      break;
    case "pricing":
      return <PricingTemplate entry={entry} />;
      break;
    default:
      return <DefaultTemplate entry={entry} />;
  }
}

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { scrollDelta: 0, scrollUpdates: 0 };
  }

  resizer = null;
  setFooterSize = () => {
    let h = document.querySelector("#footer");
    if (h) this.props.store.store("footerHeight", h.offsetHeight);
  };

  componentDidMount() {
    this.resizer = window.addEventListener("resize", this.setFooterSize);
    setTimeout(this.setFooterSize, 100);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizer);
  }

  /*

    RENDER

  */

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    let HISTORY = this.props.history;

    return (
      <Data>
        {({
          menus,
          footer,
          ENTRY,
          entries,
          assets,
          caption,
          introComplete,
          globals,
          SHOWMODAL,
          MODAL,
          SHOWPLAYER,
          PLAY,
        }) => {
          return (
            <React.Fragment>
              <div className="Site-page" id="page">
                <Link
                  to={window.location.pathname + "#back"}
                  className="Site-page-close"
                />
                <ScrollModifier reverse={false} />
                <SiteModifier auto modifier={"preloaded"} />

                {/* Site Content */}
                <div className="Site-content">
                  <div className="Site-template">
                    <ScrollTo
                      useHistory={true}
                      auto
                      ignore={({ pathname }, history) => {
                        // Return to top in all cases except when the hash matches filter or back
                        return (
                          !!window.location.hash.match("filter") ||
                          !!window.location.hash.match("back") ||
                          !!window.location.hash.match("view") ||
                          !!window.location.hash.match("play")
                        );
                      }}
                    />

                    <Template />
                  </div>
                </div>
                {/* End Site Content */}

                {/* Modifier for if site modal is set (/#/view/...) */}
                {(() => {
                  if (SHOWMODAL) {
                    let modal = document.getElementById("modal");

                    if (modal) modal.scrollTop = 0;
                    return (
                      <SiteModifier
                        modifier={"modal"}
                        auto
                        key={window.location.href}
                      />
                    );
                  } else {
                    return (
                      <SiteModifier
                        demodifier={"modal"}
                        auto
                        key={window.location.href}
                      />
                    );
                  }
                })()}

                <div className="Site-modal" id="modal">
                  <Link
                    to={window.location.pathname}
                    className="Site-modal-back"
                  />
                  <Link
                    to={window.location.pathname + "#/back/"}
                    className="Site-modal-close"
                  >
                    <Project.Icon icon="cross" />
                  </Link>

                  <div className="Site-modal-body">
                    <TemplateForModal entry={MODAL} />
                  </div>
                </div>

                {/* Site Foot */}
                <ModifierSection
                  modifier={"footerVisible"}
                  pos={window.innerHeight}
                  ignoreBottom={true}
                >
                  <div className="Site-foot" id="footer">
                    <div className="Site-foot-content">
                      <div className="Site-foot-logo">
                        <Link className="Logo Logo--alt" to={"/"}>
                          {settings.SITENAME}
                        </Link>
                      </div>

                      <div className="Site-foot-menu">
                        <Data require={["menus"]}>
                          {({ menus }) => {
                            return (
                              <ul className="List List--menu">
                                {menus
                                  .find((m) => m.slug == "main")
                                  .sets[0].links.map((item, ix) => (
                                    <li key={`footmenu${ix}`}>
                                      <Link navlink to={item.uri}>
                                        {item.text}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            );
                          }}
                        </Data>
                      </div>
                      <div className="Site-foot-channels">
                        <ul className="List List--channels">
                          {globals.links
                            .find((l) => l.label == "channels")
                            .links.map((item, ix) => (
                              <li key={`footchannels${ix}`}>
                                <a target="_blank" href={item.customLink}>
                                  {item.text}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>

                    <div className="Site-foot-nav">
                      <ul className="List List--inline">
                        {menus
                          .find((m) => m.slug == "footer")
                          .sets[0].links.map((item, ix) => (
                            <li key={`footnav${ix}`}>
                              <Link to={item.uri}>{item.text}</Link>
                            </li>
                          ))}
                        <li>&copy; {new Date().getFullYear()} Vivid City</li>
                      </ul>
                    </div>
                  </div>
                </ModifierSection>
              </div>
              {(() => {
                if (SHOWPLAYER) {
                  return (
                    <SiteModifier
                      modifier={"player"}
                      auto
                      key={window.location.href}
                    />
                  );
                } else {
                  return (
                    <SiteModifier
                      demodifier={"player"}
                      auto
                      key={window.location.href}
                    />
                  );
                }
              })()}

              <div className="Site-player" id="player">
                <Link
                  to={window.location.pathname + "#/back/"}
                  className="Site-player-back"
                />
                <Link
                  to={window.location.pathname + "#/back/"}
                  className="Site-player-close"
                >
                  <Project.Icon icon={"cross"} />
                </Link>

                <div className="Site-player-body">
                  <Project.Player
                    src={PLAY}
                    play={SHOWPLAYER}
                    muted={!SHOWPLAYER}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Data>
    );
  }
}

export default withRouter(withStore(Layout));
