// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import { useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

export default ({ items, slidesPerView = 1.5, breakpoints = {} }) => {
	return (
		<Swiper
			modules={[Autoplay]}
			spaceBetween={30}
			//slidesPerView={slidesPerView}
			centeredSlides={true}
			loop={true}
			speed={700}
			autoplay={{
				delay: 2000,
			}}
			breakpoints={breakpoints}
			//onSlideChange={() => console.log("slide change")}
			//onSwiper={(swiper) => console.log(swiper)}
		>
			{items.map((item, ix) => (
				<SwiperSlide key={`slide${ix}`}>{item}</SwiperSlide>
			))}

			<SlidePrevButton />
			<SlideNextButton />
		</Swiper>
	);
};

function SlideNextButton() {
	const swiper = useSwiper();

	return (
		<div
			className="swiper-nav swiper-nav--next"
			onClick={() => swiper.slideNext()}
		></div>
	);
}

function SlidePrevButton() {
	const swiper = useSwiper();

	return (
		<div
			className="swiper-nav swiper-nav--prev"
			onClick={() => swiper.slidePrev()}
		></div>
	);
}
