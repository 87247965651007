import React, { Component } from "react";

/*import {SiteModifier,Icon,Link,Base,Map,List,IsVisible,Media,Content,Grid} from '../../_core/components';*/

import Icon from "../../_core/components/Icon";
import Link from "../../_core/components/Link";
import Base from "../../_core/components/BaseComponent";
import Map from "../../_core/components/Map";
import List from "../../_core/components/List";
import IsVisible from "../../_core/components/IsVisible";
import Media from "../../_core/components/Media";
import Content from "../../_core/components/Content";
import Grid from "../../_core/components/Grid";
import Nl2br from "../../_core/components/Nl2br";
import SiteModifier from "../../_core/components/SiteModifier";
import EqualHeight from "../../_core/components/EqualHeight";
import DateFormat from "../../_core/components/DateFormat";
import ViewportProgress from "../../_core/components/ViewportProgress";
import Toggle from "../../_core/components/Toggle";

import { Entry, Asset, Data } from "../../_core/models";
import { Each } from "../../_core/utils";
import { withStore } from "../../_core/utils/Store";

import Globals from "../../_core/helpers/Globals";

import "../../_core/helpers/String";

import Masonry from "react-masonry-component";

// Project
import * as Project from "../../components";

// Conf
import conf from "../../_core/_conf";

import mapTint from "../../json/mapTint";

class Blocks extends Component {
  sections = [];
  componentDidMount = () => {};
  cleanUri = (uri) => {
    let settings = conf.get();
    return uri.replace(settings.CDN_HOSTNAME, "");
  };
  makeBlocks = () => {
    this.section = [];
    let blocks = this.props.blocks || [];

    if (!blocks.length) {
      this.sections = [];
      return;
    }

    // Get the background positions...
    if (blocks[0].type != "background")
      blocks.unshift({
        type: "background",
        style: "none",
      });

    let sections = [];
    let section = -1;

    blocks.forEach((block) => {
      if (block.type == "background") {
        section++;
        sections[section] = { ...block, blocks: [] };
      } else {
        sections[section].blocks.push(block);
      }
    });

    this.sections = sections;
  };
  getBlock = (origBlock, entry, bix) => {
    let _this = this;
    let SETTINGS = conf.get();

    let block = { ...origBlock };

    switch (block.type) {
      case "swiper":
        return (
          <div key={`block_swiper_${bix}`} className="Block--swiper">
            <div className="Block-content">
              <Project.Swiper
                items={block.items}
                slidesPerView={block.slidesPerView}
                breakpoints={block.breakpoints}
              />
            </div>
          </div>
        );
      case "dotmap":
        return (
          <div key={`block_dotmap_${bix}`} className="Block--dotmap">
            <div className="Block-content">
              <Content>
                <h3>Explore</h3>
              </Content>

              <div className="Block-list">
                <ul className="List List--explore">
                  <li>
                    <Link to={`#/view/screens/filter/region/europe/`}>
                      Europe
                    </Link>
                  </li>
                  <li>
                    <Link to={`#/view/screens/filter/region/usa/`}>USA</Link>
                  </li>
                  <li>
                    <Link to={`#/view/screens/filter/region/mena/`}>MENA</Link>
                  </li>
                  <li>
                    <Link to={`#/view/screens/filter/region/asia-pacific/`}>
                      Asia & Pacific
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="Block-map">
                <img src={`/ui/img/map.svg`} />
              </div>
            </div>
          </div>
        );

      case "logos":
        return (
          <div key={`block_logo_${bix}`} className="Block--logos">
            <div className="Block-content">
              <Content>
                <h3>Selected Clients</h3>
              </Content>
              <div className="Block-logos">
                <div data-grid-of="4" data-mobile-grid-of="2">
                  <div>
                    <img src={`/ui/img/logos/lacoste.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/turkish-airlines.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/ita.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/michelin.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/amazon.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/evonik.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/nintendo.svg`} />
                  </div>
                  <div>
                    <img src={`/ui/img/logos/indonesia.svg`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "reel":
        return (
          <div key={`block_reel_${bix}`} className="Block--reel">
            <div className="Block-content">
              <Project.Reel
                src={block.src}
                expandSrc={block.expandSrc ? block.expandSrc : block.src}
              />
            </div>
          </div>
        );
      case "global":
        return (
          <Entry
            key={`block_global_${bix}`}
            id={block.block}
            render={(globalBlock) => (
              <Each
                items={globalBlock.blocks}
                render={(item, ix) => {
                  // Copy size from the current page's block into the global block
                  let b = { ...item, size: block.size };

                  return _this.getBlock(b, entry, ix);
                }}
              />
            )}
          />
        );

      case "masonry":
        let masonry = block.items.map((i, ix) => {
          return (
            <div className="List-item" key={`masonry${ix}`}>
              {/* Item--masonry */}
              <div className={"Item Item--masonry"}>
                <div className="Item-media">
                  <Link prefix={"#/view"} to={i.uri}>
                    <Media id={i.thumbnail} fluid={false} />
                  </Link>
                </div>

                <div className="Item-date">
                  <DateFormat time={i.postDate} format={"DD MMM YYYY"} />
                </div>

                <div className="Item-title">
                  <h1>{i.title}</h1>
                </div>

                <div className="Item-body">
                  <Content html={i.metaDescription} />
                </div>

                <div className="Item-link">
                  <Link prefix={"#/view"} to={i.uri}>
                    Read More
                  </Link>
                </div>
              </div>
              {/* End Item--masonry */}
            </div>
          );
        });

        return (
          <div
            key={`block_masonry_${bix}`}
            className={"Block Block--masonry Block--" + block.size}
            data-type={block.type}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return <div className="Block-heading">{block.heading}</div>;
                }
              })()}

              <Masonry
                key={entry.id}
                refoff={function (c) {
                  this.masonry = this.masonry || c.masonry;
                }.bind(this)}
                className="List List--masonry"
                options={{
                  columnWidth: ".List-sizer",
                  itemSelector: ".List-item",
                  transitionDuration: 0,
                }}
              >
                <div className="List-sizer" />
                {masonry}
              </Masonry>
            </div>
          </div>
        );

      case "divider":
        return (
          <div
            key={`block_divider_${bix}`}
            className={"Block Block--divider Block--" + block.size}
            data-type={block.type}
          />
        );

      case "anchor":
        return (
          <a
            key={`block_anchor_${bix}`}
            className={"Block Block--anchor"}
            data-type={block.type}
            id={block.label}
            name={block.label}
          />
        );

      case "blank":
        return (
          <div
            key={`block_blank_${bix}`}
            className={"Block Block--blank Block--" + block.size}
            data-type={block.type}
          />
        );

      case "item":
        return (
          <Asset
            key={`block_item_${bix}`}
            id={[block.image]}
            results={(assets) => {
              let link =
                block.linkTo && block.linkTo.length
                  ? _this.props.data.entries.find(
                      (e) => e.id == block.linkTo[0]
                    ).uri
                  : block.customLink;

              return (
                <div
                  className={
                    "Block Block--item Block--" +
                    block.size +
                    " Block--orientation-" +
                    (assets.length ? assets[0].orientation : "default") +
                    " Block--" +
                    block.style +
                    " Block--" +
                    (assets.length ? "hasImage" : "noImage") +
                    " Block--" +
                    (link ? "hasLink" : "noLink")
                  }
                  data-type={block.type}
                >
                  <div className="Block-content">
                    {(() => {
                      if (link) {
                        return (
                          <Link
                            className={"Item Item--block Item--" + block.aspect}
                            to={link}
                          >
                            <div className="Item-content">
                              <div>
                                {(() => {
                                  if (assets.length) {
                                    return (
                                      <div className="Item-media">
                                        <Media
                                          asset={assets[0]}
                                          fluid={false}
                                          ratio={6 / 4}
                                        />
                                      </div>
                                    );
                                  }
                                })()}

                                <div className="Item-head">
                                  <Content html={block.heading} />
                                </div>
                                {(() => {
                                  if (block.date && block.date.from) {
                                    return (
                                      <div className="Item-date">
                                        <Content>
                                          <h3>
                                            <DateFormat
                                              time={block.date.from}
                                              to={block.date.to}
                                              format={"DD MMM YYYY"}
                                            />
                                          </h3>
                                        </Content>
                                      </div>
                                    );
                                  }
                                })()}
                                <div className="Item-body">
                                  <Content html={block.text} />
                                </div>
                              </div>
                              {(() => {
                                if (block.callToAction) {
                                  return (
                                    <div className="Item-foot">
                                      {block.callToAction}
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          </Link>
                        );
                      } else {
                        return (
                          <div
                            className={"Item Item--block Item--" + block.aspect}
                          >
                            <div className="Item-content">
                              <div>
                                {(() => {
                                  if (assets.length) {
                                    return (
                                      <div className="Item-media">
                                        <Media
                                          asset={assets[0]}
                                          fluid={false}
                                          ratio={6 / 4}
                                        />
                                      </div>
                                    );
                                  }
                                })()}

                                <div className="Item-head">
                                  <Content html={block.heading} />
                                </div>
                                {(() => {
                                  if (block.date && block.date.from) {
                                    return (
                                      <div className="Item-date">
                                        <Content>
                                          <h3>
                                            <DateFormat
                                              time={block.date.from}
                                              to={block.date.to}
                                              format={"DD.MM.YYYY"}
                                            />
                                          </h3>
                                        </Content>
                                      </div>
                                    );
                                  }
                                })()}
                                <div className="Item-body">
                                  <Content html={block.text} />
                                </div>
                              </div>
                              {(() => {
                                if (block.callToAction) {
                                  return (
                                    <div className="Item-foot">
                                      {block.callToAction}
                                    </div>
                                  );
                                }
                              })()}
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              );
            }}
          />
        );

      case "items":
        return (
          <div
            key={`block_items_${bix}`}
            className="Block Block--items Block--full"
          >
            <div className="Block-content">
              <Content html={block.text} />
            </div>
            <div className={"Block-items Block-items--" + block.itemSize}>
              <div className="Block-item-grid">
                {block.items.map((i, ix) => (
                  <div
                    key={`itemblock${ix}`}
                    className={"Block-item Block-item--" + block.itemSize}
                  >
                    <div className="Item Item--basic">
                      <div className="Item-media">
                        <Media id={i.image[0]} fluid={false} ratio={6 / 4} />
                      </div>
                      <div className="Item-head">
                        <Content html={i.text} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      case "calculator":
        return (
          <div
            key={`block_calculator_${bix}`}
            className={`Block Block--calculator`}
          >
            <div className="Block-content">
              <Project.Calculator />
            </div>
          </div>
        );
      case "custom":
        return (
          <div
            key={`block_custom_${bix}`}
            className={`Block Block--${block.size} Block--${block.style}`}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <Content>{block.heading}</Content>
                    </div>
                  );
                }
              })()}

              {block.content}
            </div>
          </div>
        );

      case "definitionList":
        return (
          <div
            key={`block_definitationlist_${bix}`}
            className={
              "Block Block--definitionList Block--" +
              block.size +
              " Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <dl className="List List--definition">
                {block.items.map((i, ix) => (
                  <li key={`definition${ix}`}>
                    <dt>
                      <Content html={i.heading} />
                    </dt>
                    <dd>
                      <Content html={i.text} />
                    </dd>
                  </li>
                ))}
              </dl>
            </div>
          </div>
        );

      case "dates":
        return (
          <div
            key={`block_dates_${bix}`}
            className={
              "Block Block--dates Block--" +
              block.size +
              " Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <dl className="List List--dates">
                {block.items.map((i, ix) => (
                  <li key={`dates${ix}`} className="Item Item--calendar">
                    <dt className="Item-head">
                      <div
                        className={
                          "Item-date " + (i.numberTo ? "Item-date--hasTo" : "")
                        }
                      >
                        {(() => {
                          if (i.numberTo) {
                            return (
                              <span>
                                {i.numberFrom}
                                <sup>{i.ordinalFrom}</sup> &mdash; {i.numberTo}
                                <sup>{i.ordinalTo}</sup>
                              </span>
                            );
                          } else {
                            return (
                              <span>
                                {i.numberFrom}
                                <sup>{i.ordinalFrom}</sup>
                              </span>
                            );
                          }
                        })()}
                      </div>
                      <div className="Item-title">
                        <h1>{i.heading}</h1>
                        <h2>{i.subheading}</h2>
                        <h3>{i.dateText}</h3>
                      </div>
                    </dt>
                    <dd className="Item-body">
                      <Content html={i.text} />
                    </dd>
                  </li>
                ))}
              </dl>
            </div>
          </div>
        );

      case "gallery":
        let images = block.images || [];

        // Gallery -> Carousel
        if (block.style == "carousel") {
          let gsettings = { slidesToShow: 1, variableWidth: true };

          gsettings.variableWidth = false;
          gsettings.centerMode = false;

          return (
            <div
              className={
                "Block Block--carousel Block--" +
                block.style +
                " Block--" +
                block.size
              }
              data-type={block.type}
              key={JSON.stringify(images)}
            >
              <div className="Block-content">
                <div className="Block-text">
                  {(() => {
                    if (block.heading) {
                      return (
                        <div className="Block-heading">
                          <Nl2br text={block.heading} />
                        </div>
                      );
                    }
                  })()}

                  {/* Text / Article */}
                  <Grid modifiers={["article"]}>
                    <div className="Grid-item">
                      <Content
                        modifier={"article Content--" + style}
                        html={block.text}
                      />
                    </div>
                    {(() => {
                      if (
                        block.caption &&
                        !block.caption.match("data-mce-bogus")
                      ) {
                        return (
                          <div className="Grid-item Grid-item--caption">
                            <Content modifier="caption" html={block.caption} />
                          </div>
                        );
                      }
                    })()}
                  </Grid>
                </div>

                <div className="Block-items">
                  <Project.CarouselGallery
                    modifiers={[block.style, block.size]}
                    settings={gsettings}
                  >
                    {images.map((image, ix) => (
                      <div
                        key={`carousel${ix}`}
                        className="CarouselGallery-item"
                      >
                        <Asset
                          id={image.image}
                          render={(asset) => {
                            return (
                              <div
                                className={
                                  "Item Item--gallery Item--" + image.aspect
                                }
                              >
                                <div className="Item-media">
                                  <Media
                                    asset={asset}
                                    fluid={false}
                                    ratio={6 / 4}
                                  />
                                </div>
                                {(() => {
                                  if (image.showCaption) {
                                    return (
                                      <div className="Item-body">
                                        <div className="Item-caption">
                                          <Content
                                            modifier="caption"
                                            html={image.caption}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                })()}
                              </div>
                            );
                          }}
                        />
                      </div>
                    ))}
                  </Project.CarouselGallery>
                </div>
              </div>
            </div>
          );
        }

        // Gallery -> Not carousel

        return images.map((image, imgix) => (
          <Asset
            key={`asset${imgix}${image.image}`}
            id={image.image}
            render={(asset) => {
              let ratio = 6 / 4;
              if (block.aspect && block.aspect == "portrait") ratio = 4 / 6;
              if (block.aspect && block.aspect == "natural")
                ratio = asset.width / asset.height;

              return (
                <div
                  key={image.image[0]}
                  className={
                    `Block Block--image Block--${
                      block.size || "full"
                    } Block--orientation-` + asset.orientation
                  }
                  data-type={block.type}
                >
                  <div className="Block-content">
                    {(() => {
                      if (!imgix) {
                        return (
                          <div className="Block-text">
                            {(() => {
                              if (block.heading) {
                                return (
                                  <div className="Block-heading">
                                    <Nl2br text={block.heading} />
                                  </div>
                                );
                              }
                            })()}

                            {/* Text / Article */}
                            <Grid modifiers={["article"]}>
                              <div className="Grid-item">
                                <Content
                                  modifier={"article Content--" + style}
                                  html={block.text}
                                />
                              </div>
                              {(() => {
                                if (
                                  block.caption &&
                                  !block.caption.match("data-mce-bogus")
                                ) {
                                  return (
                                    <div className="Grid-item Grid-item--caption">
                                      <Content
                                        modifier="caption"
                                        html={block.caption}
                                      />
                                    </div>
                                  );
                                }
                              })()}
                            </Grid>
                          </div>
                        );
                      }
                    })()}

                    <div className={"Item Item--gallery Item--" + image.aspect}>
                      <div className="Item-media">
                        <Media asset={asset} fluid={false} ratio={ratio} />
                      </div>
                      {(() => {
                        if (image.showCaption) {
                          return (
                            <div className="Item-body">
                              <div className="Item-caption">
                                <Content
                                  modifier="caption"
                                  html={image.caption}
                                />
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        ));

      case "image":
        return (
          <Asset
            key={`block_image_${bix}`}
            id={block.image}
            render={(asset) => {
              let ratio = asset.width / asset.height;
              if (block.aspect && block.aspect == "portrait") ratio = 4 / 6;
              if (block.aspect && block.aspect == "landscape") ratio = 6 / 4;

              return (
                <div
                  key={block.image[0]}
                  className={
                    `Block Block--image Block--${
                      block.size || "full"
                    } Block--orientation-` + asset.orientation
                  }
                  data-type={block.type}
                >
                  <div className="Block-content">
                    <div className={"Item Item--gallery Item--" + block.aspect}>
                      <div className="Item-media">
                        <Media asset={asset} fluid={false} ratio={ratio} />
                      </div>
                      {(() => {
                        if (block.showCaption) {
                          return (
                            <div className="Item-body">
                              <div className="Item-caption">
                                <Content
                                  modifier="caption"
                                  html={block.caption}
                                />
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        );
      case "contacts":
        return (
          <div
            key={`block_contacts_${bix}`}
            className="Block Block--text Block--contacts"
          >
            <div className="Block-content">
              <div className="Block-heading">
                Get
                <br />
                in touch
              </div>
            </div>
            <div className="Block-sections">
              <div className="Block-section">
                <div className="Content">
                  <h4>Head office</h4>
                </div>

                <div
                  className="Block-section-grid"
                  data-grid-of="2"
                  data-mobile-grid-of="1"
                >
                  <div>
                    <Data>
                      {({ globals }) => (
                        <Content html={globals.globalAddress} />
                      )}
                    </Data>
                  </div>
                  <div>
                    <Data>
                      {({ globals }) => (
                        <dl>
                          <li>
                            <dt>
                              <Project.Icon icon="envelope" />
                            </dt>
                            <dd>
                              <Content>
                                <p>
                                  <a href={`mailto:${globals.globalEmail}`}>
                                    {globals.globalEmail}
                                  </a>
                                </p>
                              </Content>
                            </dd>
                          </li>
                          <li>
                            <dt>
                              <Project.Icon icon="telephone" />
                            </dt>
                            <dd>
                              <Content>
                                <p>{globals.globalPhone}</p>
                              </Content>
                            </dd>
                          </li>
                        </dl>
                      )}
                    </Data>
                  </div>

                  <div>
                    <Content>
                      <h4>Follow</h4>
                    </Content>
                    <Data>
                      {({ globals }) => (
                        <ul className="List List--inline">
                          {globals.links
                            .find((l) => l.label == "social")
                            .links.map((item, ix) => (
                              <li key={`social${ix}`}>
                                <a target="_blank" href={item.customLink}>
                                  <Icon glyph type={item.text} />
                                </a>
                              </li>
                            ))}
                        </ul>
                      )}
                    </Data>
                  </div>
                </div>
              </div>
              <Data>
                {({ globals }) => (
                  <div clasName="Block-sections">
                    {globals.globalContacts.map((item, ix) => (
                      <div
                        className={`Block-section Block-section--${
                          item.contacts.length <= 1 ? "hasOne" : "hasMany"
                        }`}
                      >
                        <div className="Content">
                          <h4>{item.region}</h4>
                        </div>

                        <ul className="List List--contacts">
                          {item.contacts.map((contact) => (
                            <li>
                              <Content>
                                <p>
                                  {contact.country} {contact.role}
                                  {!!contact.fullName && <br />}
                                  {contact.fullName}
                                  <br />—<br />
                                  <a href={`mailto:${contact.email}`}>
                                    {contact.email}
                                  </a>
                                  {!!contact.phone && <br />}
                                  {contact.phone}
                                </p>
                              </Content>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </Data>
            </div>
          </div>
        );
      case "text":
        var style = block.style || "default";
        return (
          <div
            key={`block_text_${bix}`}
            className={
              "Block Block--text Block--" +
              block.size +
              "  Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <Nl2br text={block.heading} />
                    </div>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                {(() => {
                  if (block.caption && !block.caption.match("data-mce-bogus")) {
                    return (
                      <div className="Grid-item Grid-item--caption">
                        <Content modifier="caption" html={block.caption} />
                      </div>
                    );
                  }
                })()}
              </Grid>

              {(() => {
                if (block.links && block.links.length) {
                  return (
                    <Grid modifiers={["links"]}>
                      <Each
                        items={block.links}
                        render={(item) => {
                          let uri =
                            item.customLink && item.customLink.length
                              ? item.customLink
                              : this.cleanUri(item.linkTo);
                          return (
                            <div className="Grid-item">
                              <Link
                                to={uri}
                                className={
                                  "Link Link--callToAction Link--" + block.style
                                }
                                deactivate={"#nav"}
                              >
                                <span className="Link-body">
                                  {item.callToAction}
                                </span>
                              </Link>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  );
                }
              })()}
            </div>
          </div>
        );

      case "section":
      case "default":
        var style = block.style || "default";
        return (
          <div
            key={`block_section_${block.type}_${bix}`}
            className={
              "Block Block--section Block--" +
              block.size +
              "  Block--" +
              block.style +
              " Block--" +
              block.layout
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <div className="Block-text">
                {(() => {
                  if (block.heading) {
                    return (
                      <div className="Block-heading">
                        <Nl2br text={block.heading} />
                      </div>
                    );
                  }
                })()}

                {/* Text / Article */}
                <Grid modifiers={["article"]}>
                  <div className="Grid-item">
                    <Content
                      modifier={"article Content--" + style}
                      html={block.text}
                    />
                  </div>
                  {(() => {
                    if (
                      block.caption &&
                      !block.caption.match("data-mce-bogus")
                    ) {
                      return (
                        <div className="Grid-item Grid-item--caption">
                          <Content modifier="caption" html={block.caption} />
                        </div>
                      );
                    }
                  })()}
                </Grid>

                {(() => {
                  if (block.links && block.links.length) {
                    return (
                      <Grid modifiers={["links"]}>
                        <Each
                          items={block.links}
                          render={(item) => {
                            let uri =
                              item.customLink && item.customLink.length
                                ? item.customLink
                                : item.linkTo;
                            return (
                              <div className="Grid-item">
                                <Link
                                  to={uri}
                                  className={
                                    "Link Link--callToAction Link--" +
                                    block.style
                                  }
                                  deactivate={"#nav"}
                                >
                                  <span className="Link-body">{item.text}</span>
                                </Link>
                              </div>
                            );
                          }}
                        />
                      </Grid>
                    );
                  }
                })()}
              </div>

              <div className="Block-items">
                <ul className={"List List--" + block.itemStyle}>
                  <Each
                    items={block.items}
                    render={(item, ix) => {
                      return (
                        <li
                          className={"Block-item Block-item--" + block.itemSize}
                        >
                          {BlockItem({
                            ...item,
                            style: block.itemStyle,
                            number: ix + 1,
                          })}
                        </li>
                      );
                    }}
                  />
                </ul>
              </div>
            </div>
          </div>
        );

      case "newsletter":
        return (
          <div
            key={`block_newsletter_${bix}`}
            className={
              "Block Block--section Block--default Block--newsletter Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <div className="Block-text">
                {(() => {
                  if (block.heading) {
                    return (
                      <div className="Block-heading">
                        <Nl2br text={block.heading} />
                      </div>
                    );
                  }
                })()}

                {/* Text / Article */}
                <Grid modifiers={["article"]}>
                  <div className="Grid-item">
                    <Content
                      modifier={"article Content--" + style}
                      html={block.text}
                    />
                  </div>
                  {(() => {
                    if (
                      block.caption &&
                      !block.caption.match("data-mce-bogus")
                    ) {
                      return (
                        <div className="Grid-item Grid-item--caption">
                          <Content modifier="caption" html={block.caption} />
                        </div>
                      );
                    }
                  })()}
                </Grid>

                {(() => {
                  if (block.links && block.links.length) {
                    return (
                      <Grid modifiers={["links"]}>
                        <Each
                          items={block.links}
                          render={(item) => {
                            let uri =
                              item.customLink && item.customLink.length
                                ? item.customLink
                                : item.linkTo;
                            return (
                              <div className="Grid-item">
                                <Link
                                  to={uri}
                                  className={
                                    "Link Link--callToAction Link--" +
                                    block.style
                                  }
                                  deactivate={"#nav"}
                                >
                                  <span className="Link-body">{item.text}</span>
                                </Link>
                              </div>
                            );
                          }}
                        />
                      </Grid>
                    );
                  }
                })()}
              </div>

              <div className="Block-items">
                <Project.Newsletter />
              </div>
            </div>
          </div>
        );

      case "videoTeaser":
        var style = block.style || "default";
        return (
          <div
            key={`block_teaser_${bix}`}
            className={
              "Block Block--videoTeaser Block--" +
              block.size +
              "  Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  let uri = null;
                  let prefix = null;

                  if (block.links.length) {
                    uri =
                      block.links[0].customLink &&
                      block.links[0].customLink.length
                        ? block.links[0].customLink
                        : block.links[0].linkTo;
                    prefix =
                      block.links[0].customLink &&
                      block.links[0].customLink.length
                        ? null
                        : "#/view";
                  }
                  return (
                    <Link to={uri} prefix={prefix}>
                      <div className="Block-heading">
                        <Nl2br text={block.heading} />
                      </div>
                    </Link>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                {(() => {
                  if (block.caption && !block.caption.match("data-mce-bogus")) {
                    return (
                      <div className="Grid-item Grid-item--caption">
                        <Content modifier="caption" html={block.caption} />
                      </div>
                    );
                  }
                })()}
              </Grid>

              <Grid modifiers={["links"]}>
                <Each
                  items={block.links}
                  render={(item) => {
                    let uri =
                      item.customLink && item.customLink.length
                        ? item.customLink
                        : item.linkTo;
                    return (
                      <div className="Grid-item">
                        <Link
                          to={uri}
                          prefix={
                            item.customLink && item.customLink.length
                              ? null
                              : "#/view"
                          }
                          className={
                            "Link Link--callToAction Link--" + block.style
                          }
                          deactivate={"#nav"}
                        >
                          <span className="Link-body">{item.text}</span>
                        </Link>
                      </div>
                    );
                  }}
                />
              </Grid>
            </div>
          </div>
        );

      case "dotAnimation":
        var style = block.style || "default";
        return (
          <div
            key={`block_dotanimation_${bix}`}
            className={
              "Block Block--text Block--" +
              block.size +
              "  Block--dotAnimation Block--" +
              block.layout
            }
            data-type={block.type}
          >
            <Asset
              id={block.illustration}
              render={(asset) => {
                let dir = block.layout == "imageLeft" ? "left" : "right";

                return (
                  <ViewportProgress
                    onProgress={({ progress }) => {
                      Globals.set("DOT_IMG", asset.sizes.small);
                      Globals.set("DOT_DIR", dir);
                      Globals.set("DOT_PROGRESS", progress);
                    }}
                  >
                    <div className="Block-marker" />
                  </ViewportProgress>
                );
              }}
            />

            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <Nl2br text={block.heading} />
                    </div>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                {(() => {
                  if (block.caption && !block.caption.match("data-mce-bogus")) {
                    return (
                      <div className="Grid-item Grid-item--caption">
                        <Content modifier="caption" html={block.caption} />
                      </div>
                    );
                  }
                })()}
              </Grid>

              {(() => {
                if (block.links && block.links.length) {
                  return (
                    <Grid modifiers={["links"]}>
                      <Each
                        items={block.links}
                        render={(item) => {
                          let uri =
                            item.customLink && item.customLink.length
                              ? item.customLink
                              : item.linkTo;
                          return (
                            <div className="Grid-item">
                              <Link
                                to={uri}
                                className={
                                  "Link Link--callToAction Link--" + block.style
                                }
                                deactivate={"#nav"}
                              >
                                <span className="Link-body">{item.text}</span>
                              </Link>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  );
                }
              })()}
            </div>

            <div className="Block-items">
              <Each
                items={block.numberList}
                render={(item) => {
                  return (
                    <div>
                      <div className="Item--num">
                        <span className="Item-intro">{item.pre}</span>
                        <span
                          className={
                            "Item-big " +
                            (item.superscript ? "Item-big--hasSup" : "")
                          }
                        >
                          <span className="Item-grad">{item.number}</span>
                          <sup>{item.superscript}</sup>
                        </span>{" "}
                        {item.detail}
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        );

      case "folio":
        return (
          <div
            key={`block_folio_${bix}`}
            className={"Block Block--folio"}
            data-type={block.type}
          >
            <Entry
              id={block.screens}
              results={(items) => (
                <Project.Folio
                  title={block.heading}
                  items={items}
                  linkTo={block.linkTo}
                  callToAction={block.callToAction}
                />
              )}
            />
          </div>
        );

      case "sort":
        return (
          <div
            key={`block_sort_${bix}`}
            className={
              "Block Block--text Block--" +
              block.size +
              "  Block--" +
              (block.style || "default")
            }
            data-type={block.type}
          >
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <Nl2br text={block.heading} />
                    </div>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                <div className="Grid-item Grid-item--sort">
                  <Data>
                    {({ SORT }) => {
                      return (
                        <Toggle autoclose tag={"div"} className="Dropdown">
                          <div className="Dropdown-head">
                            {SORT ? `${SORT}` : `Sort`}
                          </div>
                          <div className="Dropdown-arrow">
                            <Icon glyph type="down" />
                          </div>
                          <div className="Dropdown-body">
                            <ul className="List List--dropdown">
                              <li>
                                <Link navlink to={"#/sort/Old-New/"}>
                                  Old—New
                                </Link>
                              </li>
                              <li>
                                <Link navlink to={"#/sort/New-Old/"}>
                                  New—Old
                                </Link>
                              </li>
                              <li>
                                <Link navlink to={"#/sort/A-Z/"}>
                                  A—Z
                                </Link>
                              </li>
                              <li>
                                <Link navlink to={"#/sort/Z-A/"}>
                                  Z—A
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Toggle>
                      );
                    }}
                  </Data>
                </div>
              </Grid>
            </div>
          </div>
        );

      case "map":
        return (
          <div
            key={`block_map_${bix}`}
            className={
              "Block Block--map Block--" +
              block.size +
              "  Block--" +
              block.style
            }
            data-type={block.type}
          >
            <div className="Block-content">
              <Map
                lat={block.latitude}
                lng={block.longitude}
                styles={mapTint("#cab7f3", "#ffffff", "#dbdcff")}
                markers={[
                  {
                    lat: block.latitude,
                    lng: block.longitude,
                    content: (
                      <div className={"Map-card "}>
                        <div className={"Map-card-fov"}>
                          <div
                            className="fov"
                            style={{ transform: `rotate(${block.angle}deg)` }}
                          />
                        </div>
                        <div className="Map-card-dot" />
                      </div>
                    ),
                  },
                ]}
              ></Map>
            </div>
          </div>
        );

      default:
        return (
          <div
            key={`block_unknown_${bix}`}
            data-type={block.type}
            className={
              "Block Block--" +
              block.type +
              " Block--unknown Block--" +
              block.size
            }
          >
            {JSON.stringify(block)}
          </div>
        );
    }
  };
  render() {
    this.makeBlocks();
    //console.log('blocks',this.sections);

    let { sections } = this;
    let modifiers = this.props.modifiers || [];
    let entry = this.props.entry || false;

    return (
      <Base family="Blocks" modifiers={modifiers}>
        {sections.map((section, ix) => {
          return (
            <div
              key={entry.id + "_" + ix}
              className={"Blocks-section Blocks-section--" + section.style}
            >
              {section.blocks.map((block, ix) => {
                return this.getBlock(block, entry, ix);
              })}
            </div>
          );
        })}
      </Base>
    );
  }
}

function Block(block) {}

function BlockItem(item) {
  //return <div>{JSON.stringify(item)}</div>;

  if (item.linkTo && item.linkTo.length) {
    return (
      <Entry
        id={item.linkTo}
        render={(entry) => {
          let itemProps = {
            ...item,
            url: entry.uri,
            image:
              item.inheritContentFromLink && !item.image.length
                ? entry.thumbnail
                : item.image,
            heading:
              item.inheritContentFromLink && !item.heading
                ? entry.title
                : item.heading,
            text:
              item.inheritContentFromLink && !item.text
                ? entry.metaDescription
                : item.text,
            style: item.style,
          };

          return <Item {...itemProps} />;
        }}
      />
    );
  } else {
    let itemProps = {
      ...item,
      url: item.customLink,
      image: item.image,
      heading: item.heading,
      text: item.text,
      style: item.style,
    };

    return <Item {...itemProps} />;
  }
}

function Item(item) {
  //return <div>{JSON.stringify(item)}</div>;

  let number = item.number || 0;
  return (
    <div data-number={number} className={"Item Item--" + item.style}>
      {(() => {
        if (item.image && item.image.length) {
          return (
            <Link to={item.url}>
              <div className="Item-media">
                <Media id={item.image} fluid={false} ratio={6 / 4} />
              </div>
            </Link>
          );
        }
      })()}
      {(() => {
        if (item.heading) {
          return (
            <div data-number={number} className="Item-title">
              <h1>{item.heading}</h1>
            </div>
          );
        }
      })()}
      {(() => {
        if (item.text) {
          return (
            <div className="Item-body">
              <Content html={item.text} />
            </div>
          );
        }
      })()}
      {(() => {
        if (item.callToAction) {
          return (
            <div className="Item-link">
              <Link to={item.url}>
                <span>{item.callToAction}</span>
              </Link>
            </div>
          );
        }
      })()}
    </div>
  );
}

export default withStore(Blocks);
