import React, { Component } from 'react';


class SupportsWebGL extends Component {

hasChecked = false;
supported = false;

constructor(props) {
    super(props);
    this.state = {};
    this.objects = {};
  }

check = () => {
  
  var canvas = document.createElement("canvas");
    // Get WebGLRenderingContext from canvas element.
    var gl = canvas.getContext("webgl")
      || canvas.getContext("experimental-webgl");
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
      this.supported = true;
    } else {
      this.supported = false;
    }

    this.hasChecked = true;

}


  render() {

    if(!this.hasChecked) this.check();

    if(this.props.hasOwnProperty('render')){
      return this.props.render({supported:this.supported});
    }

    if(this.supported) return this.props.supported();
    else return this.props.fallback();

  }
}

export default SupportsWebGL;
