import React, { Component } from "react";

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from "../_core/components/PageElement";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";
import SiteModifier from "../_core/components/SiteModifier";
import FadeIn from "../_core/components/FadeIn";

import Media from "../_core/components/Media";

// Models
import { Asset, Entry, Data, SetData } from "../_core/models";

// Project
import * as Project from "../components";

class Default extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	/*

		RENDER

	*/

	render() {
		return (
			<Template modifiers={["homepage", "blocks"]} title={"Vivid City"}>
				<FadeIn duration={0.4} delay={0.1} key={"homepage"}>
					{/* Reset styles */}
					<SiteModifier auto demodifier={"scrolled"} />
					<SiteModifier auto demodifier={"home"} />

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks
								blocks={[
									{
										type: "calculator",
									},

									{
										type: "contacts",
									},
								]}
								modifiers={[]}
							/>
						</div>
					</div>
				</FadeIn>
			</Template>
		);
	}
}

export default Default;
