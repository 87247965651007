import React, {Component} from 'react'

import Link from '../../_core/components/Link';
import Media from '../../_core/components/Media';
import Chart from 'react-apexcharts';

/*

Charts:

https://github.com/apexcharts/react-apexcharts
https://codepen.io/apexcharts/pen/GQmoXP
https://reactjsexample.com/react-component-for-apexcharts/
https://apexcharts.com/docs/gradients/


Options ref:
https://apexcharts.com/docs/options/


*/



import * as BreakPoints from '../../_core/utils/Breakpoints';
import CarouselGallery from '../CarouselGallery';

const COLOURS = {pink:'#AB029C',purple:'#8423cd',blue : '#624292',white:'#ffffff'};

class Folio extends Component {

  slider = null;
  cur = 0;

  constructor() {
    super()
  

    this.state = {
      cur : 0,
      options: {

        annotations: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        },
        toolbar: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        labels: {
          show: false
        },
        plotOptions: {
          bar : {
          dataLabels: {
            enabled: false
          }
          }
        },
        grid : {
            borderColor: COLOURS.blue,
            strokeDashArray: 1,
            width:1,
            
            yaxis : {
              lines: {show:true}
            }
          },
        chart: {
          //id: 'apexchart-example'
          toolbar: {
            show: false
          },
          lines: {
            show: false,
          },
  
          dataLabels: {
            show: false
          }
        },
        xaxis: {
          categories: ['','',''],
          labels: {
            show: false
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          lines: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false
          },
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          }
        },
        stroke: {
          curve: 'smooth',
          colors: [COLOURS.pink,COLOURS.blue],
          width:1
        },
        fill: {
          type: 'gradient',
          colors : [COLOURS.pink,COLOURS.blue],
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: [COLOURS.purple,COLOURS.blue],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0.5,
            stops: [0, 100]
          }
        }
      },
       reach: [{
        name: 'reach-1',
        data: [0, 0, 0]
      },
      {
        name: 'reach-2',
        data: [0, 1, 0]
      }],
    area: [{
        name: 'area-1',
        data: [0]
      },
      {
        name: 'area-2',
        data: [ 1]
      }],
      optionsRadial: {

        plotOptions: {
          radialBar: {
            size:35,
            offsetX : 10,
            offsetY : 35,
            //startAngle: -135,
            //endAngle: 225,
            hollow: {
              margin: 0,
              size: '80%',
              background: 'transparent',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: false
              }
            },
            track: {
              background: COLOURS.blue,
              strokeWidth: '80%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: false,
              }
            },

            dataLabels: {
              show:false,
           
              value: {
                
                show: false,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          colors : [COLOURS.pink],
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [COLOURS.purple],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round',
          width:5
        },
        labels: ['Percent'],

      },
      seriesRadial: [0]
    }
  }

  
initSlider = (slider) => {
  this.slider = slider;
  this.goTo(0);
}
  
  goTo = (ix) => {

        

        let _this = this;

            _this.cur = ix;

            this.setState({cur:this.cur});
            if(this.slider) this.slider.slickGoTo(ix);

            let start = (this.props.items[ix].screeningStart.toFixed(2) + '').split('.');
            let hrsStart = parseInt(start[0]) + (parseInt(start[1]) / 60);

            let end = (this.props.items[ix].screeningEnd.toFixed(2) + '').split('.');
            let hrsEnd = parseInt(end[0]) + (parseInt(end[1]) / 60);

            let hrs = Math.abs(hrsStart - hrsEnd);

            
            this.setState({

              seriesRadial : [(hrs/24 * 100)],
  reach: [{
        name: 'reach-1',
        data: [0, this.props.items[ix].dailyReach / 3000000, 0]
      },
      {
        name: 'reach-2',
        data: [0, 1, 0]
      }],
    area: [{
        name: 'area-1',
        data: [this.props.items[ix].screenArea / 25000]
      },
      {
        name: 'area-2',
        data: [ 1]
      }]
})

  }

  render () {

    let items = this.props.items || [];

      return (
      <div className="Folio">

          <div className="Folio-nav">
              <div className="Content">
                <h3>{this.props.title}</h3>
              </div>
              <ul className="Folio-list">
                {items.map((item,i)=>(
                  <li onClick={()=>this.goTo(i)} className={"Folio-list-item "+((i == this.state.cur) ? 'is-active' : '')}>
                    <span className="Folio-count">{(i + 1)}.</span>
                    <div className="Folio-list-detail">
                        <h1>{item.title}</h1>
                        <h3>{item.city}</h3>
                        <Link to={item.uri}>View Details</Link>
                    </div>
                  </li>
              ))}
              </ul>
          </div>

          

          <div className="Folio-bg">
            <CarouselGallery settings={{speed:700,slidesToShow:1,variableWidth:true,autoplay: true,
  autoplaySpeed: 4000,pauseOnHover:false}} onChange={(ix)=>this.goTo(ix)} api={this.initSlider}>
              {items.map((item)=>(
                  <div className="Folio-slide">
                    <div className="Folio-item">
                      <Media id={item.thumbnail} fluid={true} />
                    </div>
                  </div>
              ))}
            </CarouselGallery>
          </div>

          <div className="Folio-foot">

              <div className="Folio-charts">
                <div>
                  <div className="Item Item--chart">
                  <div className="Item-media">
                    <div className="Item-clock">
                      <div className="Item-hand Item-hand--start" data-time={items[this.state.cur].screeningStart.toFixed(2).replace('.','')} />
                      <div className="Item-hand Item-hand--end" data-time={items[this.state.cur].screeningEnd.toFixed(2).replace('.','')}  />
                    </div>
                    <Chart options={this.state.optionsRadial} series={this.state.seriesRadial} type="radialBar" width={120} height={120} />
                  </div>
                  <div className="Item-head">
                    <h1>Screening Time</h1>
                    <p>{items[this.state.cur].screeningStart.toFixed(2)} &mdash; {items[this.state.cur].screeningEnd.toFixed(2)}</p>
                  </div>
                  </div>
                </div>
                <div>
                  <div className="Item Item--chart">
                  <div className="Item-media">
                     <Chart options={this.state.options} series={this.state.area} type="bar" width={120} height={120} />
                  </div>
                  <div className="Item-head">
                    <h1>Screen Size</h1>
                    <p>{(new Intl.NumberFormat().format(items[this.state.cur].screenArea))}m<sup>2</sup></p>
                  </div>
                  </div>
                </div>
                <div>
                  <div className="Item Item--chart">
                  <div className="Item-media">
                     <Chart options={this.state.options} series={this.state.reach} type="area" width={120} height={120} />
                  </div>
                  <div className="Item-head">
                    <h1>Daily Reach</h1>
                    <p>{(new Intl.NumberFormat().format(items[this.state.cur].dailyReach))}</p>
                  </div>
                  </div>
                </div>
               
                
                
              </div>

          </div>

          {(()=>{

            if(this.props.linkTo && this.props.linkTo.length){

              return (<div className="Folio-cta"><Link to={this.props.linkTo}>{this.props.callToAction}</Link></div>)
            }
          })()}

      </div>
      )
  }
}

export default Folio
