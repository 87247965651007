import GoogleAnalytics from "react-ga4";
const MAPSTYLES = require("./json/map.json");
const IS_PRODUCTION =
	window.location.hostname.match("vividcity.com") &&
	!window.location.hostname.match("plesk-server.com");
const CDN_HOSTNAME = "//vividcity.webcdn.network";
const hostName = IS_PRODUCTION ? CDN_HOSTNAME : "";
const d = new Date();
const CACHEBUST = false;
let CACHEBUSTER = IS_PRODUCTION
	? "?nc=" + d.getFullYear() + d.getMonth() + d.getDate() + d.getHours()
	: "?nc=" + d.getTime();
CACHEBUSTER = CACHEBUST ? CACHEBUSTER : "";
let staticJSON = IS_PRODUCTION;
let HOST = staticJSON ? "/data/" : "/";
if (
	window.location.hostname.match("localhost") ||
	window.location.hostname.match("netlify")
)
	HOST = "/json/";

const settings = {
	SITENAME: "Vivid City",
	CDN_HOSTNAME: CDN_HOSTNAME,
	MANIFEST: [
		{
			name: "entries",
			src: HOST + "entries.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
			transformer: (data) => {
				let clone = [...data];
				clone.push({
					id: "pricing",
					title: "Pricing",
					template: "pricing",
					uri: "/pricing/",
					type: "pricing",
				});
				return clone;
			},
		},
		{
			name: "assets",
			src: HOST + "assets.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "navigation",
			src: HOST + "navigation.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "filters",
			src: HOST + "filters.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "menus",
			src: HOST + "menus.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "footer",
			src: HOST + "footer.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
		},
		{
			name: "globals",
			src: HOST + "globals.json" + CACHEBUSTER,
			type: "json",
			subset: "data",
			transformer: (data) => data[0],
		},
	],
	GOOGLE_MAPS_API_KEY: "AIzaSyBGvCP2eqWTPrL1AdfDWeRNmIHqJ9igW7w",
	MAPSTYLES: MAPSTYLES,
	//GA_ID: "UA-122678876-12", // OLD
	GA_ID: "G-4Z8RGSZ4NR", // G4
	GTM_ID: "GTM-XXXX", // Tag Manger
};

/*
	Init GA
*/

GoogleAnalytics.initialize(settings.GA_ID);

export default settings;
