import React, { Component } from 'react';
import * as THREE from 'three';

var OrbitControls = require('three-orbit-controls')(THREE)

class ThreeJs extends Component {

constructor(props) {
    super(props);
    this.state = {};
    this.objects = {};
  }

componentDidMount() {

	this.init();

  window.addEventListener('resize',this.resize);

}

componentWillUnmount() {
    this.stopLoop();
    window.removeEventListener(this.resize);
  }

resize = () =>{

    const {renderer,camera} = this.objects;

    camera.aspect = this.refs.canvas.offsetWidth / this.refs.canvas.offsetHeight;
    camera.updateProjectionMatrix()

    renderer.setSize( this.refs.canvas.offsetWidth, this.refs.canvas.offsetHeight );

}

init() {

	const scene = new THREE.Scene();
 
    const camera = new THREE.PerspectiveCamera( 75, this.refs.canvas.offsetWidth / this.refs.canvas.offsetHeight, 1, 10000 );
    camera.position.z = 1000;
    
    let controls = null;
    
    if(!this.props.hasOwnProperty('controls') || this.props.controls){
      controls = new OrbitControls(camera,this.refs.canvas);
      controls.target = new THREE.Vector3(0,0,0);
      }
    const renderer = new THREE.WebGLRenderer( { alpha:true, antialias: true } );
    renderer.setClearColor( 0x000000 ,0);
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( this.refs.canvas.offsetWidth, this.refs.canvas.offsetHeight );
 

	 this.objects = {scene,camera,renderer,controls}

   if(this.props.onInit){
      const objectsAdded = this.props.onInit(this.objects);
      if(objectsAdded) Object.assign(this.objects,objectsAdded);
    }

    this.refs.canvas.appendChild( renderer.domElement );
    
    requestAnimationFrame(this.loop);

}
  
  stopLoop = () => {
    cancelAnimationFrame(this.loop);
  }
  loop = () => {
    
  	const {renderer,scene,camera} = this.objects

    if(this.props.onLoop) this.props.onLoop(this.objects);

    if(!this.props.hasOwnProperty('render') || this.props.render) renderer.render(scene,camera);
    
    requestAnimationFrame(this.loop)
  }


  render() {
    return (
      <div style={{width:'100%',height:'100%'}} ref="canvas"></div>
    );
  }
}

export default ThreeJs;
