import React, { Component } from "react";

// Conf
import conf from "./_core/_conf";

// Core
import Loader from "./_core/components/Loader";
import Media from "./_core/components/Media";
import Site from "./_core/components/Site";
import Burger from "./_core/components/Burger";
import Content from "./_core/components/Content";
import Image from "./_core/components/Image";
import SiteModifier from "./_core/components/SiteModifier";
import ViewportProgress from "./_core/components/ViewportProgress";
import FadeIn from "./_core/components/FadeIn";
import Link from "./_core/components/Link";
import Gallery from "./_core/components/Gallery";
import Transition from "./_core/components/Transition";
import Toggle from "./_core/components/Toggle";
import Html from "./_core/components/Html";
import Icon from "./_core/components/Icon";
import Map from "./_core/components/Map";
import EqualHeight from "./_core/components/EqualHeight";
import Form from "./_core/components/Form";
import Filter from "./_core/components/Filter";
import MousePos from "./_core/components/MousePos";

import { Entry, Data, Asset } from "./_core/models";
import { withStore } from "./_core/utils";
import RenderLoop from "./_core/utils/RenderLoop";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

import Globals from "./_core/helpers/Globals";

import mapTint from "./json/mapTint";

import * as Breakpoints from "./_core/utils/Breakpoints";

import SupportsWebGL from "./_core/THREE/SupportsWebGL";

// Layout
import Layout from "./templates/_layout";

// Project
import * as Project from "./components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

let TOUCH = !!("ontouchstart" in window);

let palettes = [
  // Blue
  {
    bg: "#D4E3E6",
    contrast: "#074D65",
    accent: "#D6AB4D",
  },
  // Green
  {
    bg: "#D6E1CB",
    contrast: "#14613E",
    accent: "#DF5043",
  },
  // Pink
  {
    bg: "#EFCDCD",
    contrast: "#8E3450",
    accent: "#374c98",
  },
  // Purple
  {
    bg: "#D6D2E9",
    contrast: "#6e5167",
    accent: "#609387",
  },
  // Brown
  {
    bg: "#D8CFC9",
    contrast: "#7a624f",
    accent: "#d33449",
  },
];

class Index extends Component {
  WEBGL = true;

  constructor(props) {
    super(props);
    this.state = {
      showContent: 0,
      showLogo: 0,
      scrollDelta: 0,
      scrollUpdates: 0,
      progress: 0,
    };
  }

  componentDidMount = () => {
    let _this = this;
  };

  introComplete = () => {
    if (!this.state.showLogo) {
      this.setState({ showLogo: 1 });
      //window.site.modify('introComplete');
    }
  };

  componentWillMount = () => {
    const _this = this;
  };

  activate = (id) => {
    if (this.state.hover != id) {
      this.setState({ hover: id });
    }
  };

  checkProgress = () => {
    if (!this.WEBGL) return;

    let curProgress = this.state.progress;
    let curImg = this.state.img;
    let curDir = this.state.dir;

    let dotProgress = Globals.get("DOT_PROGRESS");
    let dotImg = Globals.get("DOT_IMG");
    let dotDir = Globals.get("DOT_DIR");

    if (dotProgress != curProgress || dotDir != curDir || dotImg != curImg) {
      this.setState({ progress: dotProgress, img: dotImg, dir: dotDir });
    }
  };

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    return (
      <Site>
        <div className="Loader" />
        <canvas id="sketch" />

        <SupportsWebGL
          render={({ supported }) => {
            this.WEBGL = supported;
            return (
              <FadeIn duration={0.2}>
                <div>
                  <div className="Site-intro">
                    <div
                      className={"Intro"}
                      style={{ height: window.innerHeight }}
                    >
                      <div className="Intro-message Intro-message--logo">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 369.6 87.8"
                          xmlSpace="preserve"
                        >
                          <style>
                            {` 
        .st0{fill:#FFFFFF;}
        .st1off{fill:#FF4299;}
        `}
                          </style>

                          {/* Vivid */}
                          <path
                            className="st1 s1 v"
                            d="M8.3,4.2c-0.2-2.3-2.2-4-4.5-3.8C1.5,0.7-0.2,2.6,0,5c0.2,2.3,2.2,4,4.5,3.8C6.8,8.5,8.5,6.6,8.3,4.2z"
                          />
                          <path
                            className="st1 s2 v"
                            d="M12.6,17.1c-0.2-2.3-2.2-4-4.5-3.8c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8
          C11.1,21.4,12.8,19.4,12.6,17.1z"
                          />
                          <path
                            className="st1 s3 v"
                            d="M16.9,29.9c-0.2-2.3-2.2-4-4.5-3.8c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8
          C15.5,34.2,17.1,32.2,16.9,29.9z"
                          />
                          <path
                            className="st1 s4 v"
                            d="M21.2,42.7c-0.2-2.3-2.2-4-4.5-3.8c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8C19.8,47,21.4,45,21.2,42.7z"
                          />
                          <path
                            className="st1 s5 v"
                            d="M25.5,55.5c-0.2-2.3-2.2-4-4.5-3.8c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8
          C24.1,59.8,25.7,57.9,25.5,55.5z"
                          />
                          <polygon
                            className="st0 v"
                            points="24.4,68.7 31.6,68.7 54.7,0.8 47.5,0.8 "
                          />

                          <path
                            className="st0 v"
                            d="M133.3,0.7c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8c2.3-0.2,4-2.2,3.8-4.5C137.6,2.2,135.6,0.5,133.3,0.7
          z"
                          />
                          <rect
                            x="62.8"
                            y="23.6"
                            className="st0 v"
                            width="7"
                            height="45.2"
                          />
                          <rect
                            x="130.1"
                            y="23.6"
                            className="st0 v"
                            width="7"
                            height="45.2"
                          />
                          <path
                            className="st0 v"
                            d="M182.5,0.8v30.6h-0.2c-1.9-2.9-6-8.6-16-8.6c-9.4,0-20.9,6.7-20.9,23.3c0,14.1,9.3,23.8,21.8,23.8
          c9.3,0,13-5.6,15.1-9.1h0.2v7.9h7V0.8H182.5z M167.4,63.7c-9.5,0-14.4-7.9-14.4-17.3c0-11.9,6.9-17.2,14.6-17.2
          c7.5,0,14.8,5.5,14.8,17.3C182.5,56,176.9,63.7,167.4,63.7z"
                          />
                          <polygon
                            className="st0 v"
                            points="100,58.6 99.9,58.6 86,23.6 78.1,23.6 96.6,68.7 103,68.7 121.8,23.6 114.2,23.6 "
                          />

                          <path
                            className="st0 v"
                            d="M66,0.7c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8c2.3-0.2,4-2.2,3.8-4.5C70.3,2.2,68.3,0.5,66,0.7z"
                          />

                          {/* City */}
                          <path
                            className="st1 s1 c"
                            d="M223.7,9.4c2.3-0.2,3.9-2.1,3.7-4.4c-0.2-2.3-2.1-3.9-4.4-3.7c-2.3,0.2-3.9,2.1-3.7,4.4
          C219.5,8,221.5,9.6,223.7,9.4z"
                          />

                          <path
                            className="st1 s2 c"
                            d="M212.2,17.6c2.3-0.2,3.9-2.1,3.7-4.4c-0.2-2.3-2.1-3.9-4.4-3.7c-2.3,0.2-3.9,2.1-3.7,4.4
          C208,16.1,210,17.8,212.2,17.6z"
                          />
                          <path
                            className="st1 s3 c"
                            d="M206.1,29.9c2.3-0.2,3.9-2.1,3.7-4.4c-0.2-2.3-2.1-3.9-4.4-3.7c-2.3,0.2-3.9,2.1-3.7,4.4
          C201.9,28.5,203.9,30.1,206.1,29.9z"
                          />

                          <rect
                            x="275.7"
                            y="23.6"
                            className="st0 c"
                            width="7"
                            height="45.2"
                          />
                          <path
                            className="st0 c"
                            d="M278.8,0.7c-2.3,0.2-4,2.2-3.8,4.5c0.2,2.3,2.2,4,4.5,3.8c2.3-0.2,4-2.2,3.8-4.5C283.1,2.2,281.2,0.5,278.8,0.7
          z"
                          />
                          <path
                            className="st0 c"
                            d="M308,10.4h-7l0,13.1h-8.4v5.7h8.4l0,28.4c0,9,2.9,11.3,8.9,11.3c2.2,0,4.6-0.1,7.1-0.3l0-6.3
          c-1.3,0.2-3.1,0.3-4.7,0.3c-3.1,0-4.3-1.8-4.3-5.8l0-27.6h9.1l0-5.7H308L308,10.4z"
                          />
                          <path
                            className="st0 c"
                            d="M256.9,23.1l1.6-0.1h6.1c0,0,0-0.3-0.1-0.8c0,0,0,0,0,0c-2-9.3-8.5-22.2-29.7-22.2c-0.6,0-1.3,0.1-1.9,0.1v0.2
          c0,0,0,0,0,0V7c0.6,0,1.2-0.1,1.9-0.1C244.9,6.9,253.5,11.2,256.9,23.1z"
                          />
                          <polygon
                            className="st0 c"
                            points="361.8,23.5 347.9,61 347.7,61 333.8,23.5 325.8,23.5 344.2,67.9 335.5,87.8 343.2,87.8 369.6,23.5 "
                          />

                          <path
                            className="st0 c"
                            d="M257.4,44.2L257.4,44.2C257.4,44.2,257.4,44.2,257.4,44.2l-0.1,0.1c-1.6,6.3-4.5,11.3-9,14.6
          c-3.5,2.5-8,3.9-13.5,3.9c-0.6,0-1.1,0-1.6,0c-0.1,0-0.2,0-0.3,0v0c-17.9-0.9-24-14.9-24.1-27.8h-8c0.1,21,13.3,34.1,32.1,34.6v0
          c0.4,0,0.7,0,1.1,0c6.4,0,11.5-1.3,15.6-3.4c9.1-4.6,13.4-13.2,15.4-21.1l0,0c0.1-0.4,0.1-0.9,0.1-0.9H257.4z"
                          />
                        </svg>
                        <h2 className="Intro-strap">Unmissable Advertising</h2>
                      </div>
                    </div>
                  </div>

                  <SiteModifier
                    modifier={supported ? "webGl" : "no-webGl"}
                    auto
                  />

                  <div className="Site-canvas">
                    {(() => {
                      if (supported && 1) {
                        return (
                          <Project.VividDots
                            mouse={{ x: 0, y: 0 }}
                            playintro={1}
                            onIntroComplete={this.introComplete}
                            progress={this.state.progress}
                            img={this.state.img}
                            dir={this.state.dir}
                          />
                        );
                      }
                    })()}
                  </div>

                  <Loader
                    manifest={[...settings.MANIFEST]}
                    progress={() => {
                      return null;
                    }}
                    wait={this.WEBGL ? 2000 : 1500}
                    loaded={({ entries }) => {
                      return (
                        <Data require={["entries", "navigation"]}>
                          {({
                            globals,
                            entries,
                            structures,
                            filters,
                            FILTERING,
                            DETAILS,
                          }) => {
                            let HOME = entries.find((e) => e.uri == "/");

                            return (
                              <FadeIn duration={0.5} delay={0}>
                                <SiteModifier
                                  modifier="loadComplete"
                                  auto
                                  delay={0}
                                />
                                <SiteModifier modifier="ready" auto delay={0} />

                                {/*<RenderLoop onFrame={this.checkProgress} />*/}

                                {/* Details modifier (set in Project.Router) */}
                                {(() => {
                                  if (DETAILS) {
                                    return (
                                      <SiteModifier
                                        modifier="details"
                                        auto
                                        key={Math.random()}
                                        delay={0}
                                      />
                                    );
                                  } else {
                                    return (
                                      <SiteModifier
                                        demodifier="details"
                                        auto
                                        key={Math.random()}
                                        delay={0}
                                      />
                                    );
                                  }
                                })()}

                                <div className="Site-head" id="head">
                                  <SiteModifier demodifier="menu">
                                    <div className="Site-logo">
                                      <Link className="Logo" to={"/"}>
                                        {settings.SITENAME}
                                      </Link>
                                    </div>
                                  </SiteModifier>

                                  <div className="Site-burger">
                                    <Project.Burger />
                                  </div>
                                </div>
                                <SiteModifier toggle="menu">
                                  <div className="Site-menu">
                                    <Data require={["menus"]}>
                                      {({ menus }) => {
                                        return (
                                          <ul className="List List--nav">
                                            {menus
                                              .find((m) => m.slug == "main")
                                              .sets[0].links.map((item, ix) => (
                                                <li key={`menuli${ix}`}>
                                                  <Link navlink to={item.uri}>
                                                    {item.text}
                                                  </Link>
                                                </li>
                                              ))}
                                          </ul>
                                        );
                                      }}
                                    </Data>
                                  </div>
                                </SiteModifier>
                                {/*
                                <div className="Site-fixed-menu">
                                  <Data require={["menus"]}>
                                    {({ menus }) => {
                                      return (
                                        <ul className="List List--inline">
                                          {menus
                                            .find((m) => m.slug == "main")
                                            .sets[0].links.map((item, ix) => (
                                              <li key={``}>
                                                <span>00{ix + 1}</span>
                                                <Link navlink to={item.uri}>
                                                  {item.text}
                                                </Link>
                                              </li>
                                            ))}
                                        </ul>
                                      );
                                    }}
                                  </Data>
                                </div>*/}

                                <div className="Site-shortcuts">
                                  <ul className="List List--inline">
                                    <li>
                                      <Link
                                        className="Link--button Link--blur"
                                        navlink
                                        to={`#/view/pricing/`}
                                        data-icon="money11"
                                      >
                                        Pricing
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="Link--button Link--primary"
                                        navlink
                                        to={`#/view/screens/`}
                                        data-icon="search"
                                      >
                                        Find Screens
                                      </Link>
                                    </li>
                                  </ul>
                                </div>

                                {/* Template */}
                                <div className="Site-layout">
                                  <Project.Router />
                                </div>

                                {/* Site Social */}
                                <div className="Site-social">
                                  <ul className="List">
                                    {globals.links
                                      .find((l) => l.label == "social")
                                      .links.map((item, ix) => (
                                        <li key={`globalli${ix}`}>
                                          <a
                                            target="_blank"
                                            href={item.customLink}
                                          >
                                            <Icon glyph type={item.text} />
                                          </a>
                                        </li>
                                      ))}
                                  </ul>
                                </div>

                                <Data require={["ENTRY"]}>
                                  {({
                                    ENTRY,
                                    DETAIL,
                                    FILTERING,
                                    FILTER,
                                    SEARCH,
                                  }) => {
                                    let META = ENTRY;
                                    if (FILTERING && FILTER) META = FILTER;
                                    if (DETAILS && DETAIL) META = DETAIL;
                                    if (SEARCH)
                                      META = {
                                        ...META,
                                        metaTitle:
                                          "Search results for " + SEARCH,
                                      };
                                    if (META) {
                                      return (
                                        <Helmet>
                                          <meta
                                            property="og:description"
                                            content={META.metaDescription}
                                          />
                                          <meta
                                            name="description"
                                            content={META.metaDescription}
                                          />
                                          <meta
                                            name="twitter:description"
                                            content={META.metaDescription}
                                          />
                                          <meta
                                            name="keywords"
                                            content={META.metaKeywords}
                                          />
                                          <title>
                                            {META.metaTitle
                                              ? META.metaTitle
                                              : META.title}{" "}
                                            | Vivid City
                                          </title>
                                          {(() => {
                                            if (
                                              META.hasOwnProperty("schema") &&
                                              META.schema
                                            ) {
                                              return (
                                                <script type="application/ld+json">
                                                  {JSON.stringify(META.schema)}
                                                </script>
                                              );
                                            } else {
                                              return null;
                                            }
                                          })()}
                                        </Helmet>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }}
                                </Data>
                              </FadeIn>
                            );
                          }}
                        </Data>
                      );
                    }}
                  />
                  {/*
        <SiteModifier modifier="seenpopup">
          <div className="Site-popup">
            <div className="Site-popup-close">+</div>
            <div className="Site-popup-sections">
              <div className="Site-popup-section Site-popup-section--text">
                <Content modifier="popup">
                  
                  <h1>Free 2020 OOH brand experience forecast</h1>
                  <p>The strategies and technology to watch this year.</p>
                  <a href="https://mailchi.mp/vividcity/2020-forecast/">Get the report</a>

                </Content>
              </div>
              <div className="Site-popup-section Site-popup-section--image">
                <a href="https://mailchi.mp/vividcity/2020-forecast/" target="_blank" rel="noopener" ><img src={'/ui/img/2020-report-cover-front.png'} className="Site-popup-cover Site-popup-cover--front" /></a>
                <a href="https://mailchi.mp/vividcity/2020-forecast/" target="_blank" rel="noopener" ><img src={'/ui/img/2020-report-cover-back.png'} className="Site-popup-cover Site-popup-cover--back" /></a>
              </div>
            </div>
          </div>
        </SiteModifier>
      */}
                </div>
              </FadeIn>
            );
          }}
        />
        <div className="Site-foot-bg">
          <Data require={["footerHeight"]}>
            {({ footerHeight }) => <div style={{ height: footerHeight }} />}
          </Data>
        </div>
      </Site>
    );
  }
}

export default withRouter(withStore(Index));
