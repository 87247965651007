 // Global data
class Globals{

	data = {};
	id = Math.random();
	
	constructor() {

    }

	set(key,val){

		this.data[key] = val;

	}

	get(key){

		return this.data[key];

	}

}

export default new Globals();