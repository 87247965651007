import React, { useState, useRef, useEffect } from "react";

// Project
import * as Project from "../../components";

import Slider from "rc-slider";

// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';
import "rc-slider/assets/index.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Budget = createSliderWithTooltip(Slider);

const priceFormat = (value) => {
	return `£${new Intl.NumberFormat().format(value)}k`;
};

export default () => {
	let budgetMap = { 0: 10, 50: 50, 100: 500 };
	let [progress, setProgress] = useState(50);
	let formats = [
		{
			id: "street",
			label: "Street Screen",
			icon: "screen",
			budget: 10,
			value: 0,
		},
		{
			id: "taxi",
			label: "Taxi",
			icon: "taxi",
			budget: 30,
			value: 30,
		},
		{
			id: "bus",
			label: "Bus",
			icon: "bus",
			budget: 50,
			value: 50,
		},
		{
			id: "airport",
			label: "Airport",
			icon: "plane",
			budget: 100,
			value: 60,
		},
		{
			id: "metro",
			label: "Train & Metro",
			icon: "train",
			budget: 200,
			value: 70,
		},

		{
			id: "megascreen",
			label: "Mega Screen",
			icon: "city",
			budget: 300,
			value: 80,
		},
	];

	const setBudget = (progress) => {
		let b = 0;
		let amt, rangeMin, rangeMax;
		if (progress <= 50) {
			amt = progress / 50;
			rangeMin = budgetMap[0];
			rangeMax = budgetMap[50];
		} else {
			amt = (progress - 50) / 50;
			rangeMin = budgetMap[50];
			rangeMax = budgetMap[100];
		}

		b = (rangeMax - rangeMin) * amt + rangeMin;

		let toNearestFive = Math.ceil(b / 5);

		return toNearestFive * 5;
	};
	let budget = setBudget(progress);

	const mailLink = (budget) => {
		let to = "alistair@vividcity.com";
		let subject = `Media enquiry for ${priceFormat(budget)} budget`;
		let body = `Hi Alistair,<br>
		Please could you get in touch with me about my campaign requirements?<br>
		I would like to discuss what I could achieve for a ${priceFormat(
			budget
		)} budget<br>
		`.replace(/<br>([\s]+)/gi, "<br>");

		return `mailto:${to}?subject=${subject}&body=${body}`

			.replace(/\s/gi, "%20")
			.replace(/<br>/gi, "%0D%0A");
	};

	return (
		<div className="Item Item--calculator">
			<div className="Item-head">
				<div className="Content">
					<h1>
						Budget
						<br />
						Estimator
					</h1>
				</div>
			</div>
			<div className="Item-body">
				<div className="Item-sections">
					<div className="Item-section--slider">
						<div className="Item Item--input Item--slider">
							<div className="Content">
								<h3>Your Budget</h3>
								<h1>{priceFormat(budget)}</h1>
							</div>
							<div className="Item-body">
								<Budget
									value={progress}
									tipFormatter={(value) =>
										priceFormat(setBudget(value))
									}
									min={0}
									max={100}
									onChange={(value) => {
										setProgress(value);
									}}
									marks={{
										0: "£10k",
										50: "£50k",
										100: "£500k+",
									}}
								/>
							</div>
						</div>
						<div className="Item Item--callback">
							<a
								href={mailLink(budget)}
								className="Link Link--button"
							>
								Request call back on this estimate
							</a>
						</div>
					</div>
					<div className="Item-section--results">
						<div data-layout="vstack" data-gap="lg">
							<div className="Item">
								<div className="Content">
									<h3>Example Campaign</h3>
								</div>
								<div data-layout="cols" data-cols="3">
									<div data-layout="hstack">
										<Project.Icon icon="calendar" />
										<div className="Content">
											<h4>1 week</h4>
										</div>
									</div>
									<div data-layout="hstack">
										<Project.Icon icon="time" />
										<div className="Content">
											<h4>Peak Time</h4>
										</div>
									</div>
									<div data-layout="hstack">
										<Project.Icon icon="globe" />
										<div className="Content">
											<h4>1 Location</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="Item">
								<div className="Content">
									<h3>Example Coverage</h3>
								</div>
								<div data-layout="cols" data-cols="3">
									{formats.map((format, ix) => (
										<div
											key={`format${ix}`}
											className="Item Item--lozenge"
											data-active={
												format.budget <= budget
													? "active"
													: "inactive"
											}
											data-layout="hstack"
											onClick={() => {
												if (progress != format.value) {
													setProgress(format.value);
												} else {
													if (ix) {
														setProgress(
															formats[ix - 1]
																.value
														);
													}
												}
											}}
										>
											<div className="Content">
												<h4>{format.label}</h4>
											</div>
											<Project.Icon icon={format.icon} />
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
