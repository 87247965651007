import React, { Component } from "react";
import { Data } from "../../_core/models";
import { DataRouter } from "../../_core/controllers";

import Globals from "../../_core/helpers/Globals";

import Layout from "../../templates/_layout";

class Router extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <DataRouter
        routes={[
          // Store an ENTRY and HOME global for any URI
          {
            route: "(/.*)/?",
            data: (matches, data) => {
              let found = false;
              let home = false;

              found = data.entries.find(
                (entry) => entry.uri == window.location.pathname
              );
              home = data.entries.find((entry) => entry.uri == "/");

              let segments = window.location.hash.split("/");

              let isHome = false;

              if (found && home) isHome = found.id == home.id;

              // Reset dot progress every page load
              Globals.set("DOT_PROGRESS", 0);

              return {
                SHOWMODAL: false,
                SHOWPLAYER: false,
                FILTERS: [],
                FILTER_RESULTS: [],
                FILTERING: false,
                SEARCH: false,
                ENTRY: found,
                HOME: home,
                IS_HOME: isHome,
                SEGMENTS: segments,
              };
            },
          },
          /*{
            route:'^/$',
            data : (matches,data)=> {

              
              let SLIDESHOW = (data.hasOwnProperty('SLIDESHOW')) ? !data.SLIDESHOW : false;
              if(!data.hasOwnProperty('introComplete')) SLIDESHOW = false;

              return {SLIDESHOW:SLIDESHOW}

            }
          },*/
          // Set a MODAL variable based on a hash value
          {
            route: "/view/(.*)/?",
            data: (matches, data) => {
              let found = false;

              if (matches.length > 0) {
                let uri = window.location.href
                  .split("#/view")[1]
                  .replace(/filter\/(.*)$/gi, "");

                found = data.entries.find((entry) => entry.uri == uri);
              }

              return { SHOWMODAL: found ? true : false, MODAL: found };
            },
          },
          // Set a PLAY variable based on a hash value
          {
            route: "/play/(.*)/?",
            data: (matches, data) => {
              let query = decodeURIComponent(matches[1]);
              let decoded = atob(query);

              return { SHOWPLAYER: true, PLAY: decoded };
            },
          },
          // Set a SEARCH variable based on a hash value
          {
            route: "/find/(.*)/?",
            data: (matches, data) => {
              let query = decodeURIComponent(matches[1]);

              return { FILTERING: true, FILTERS: false, SEARCH: query };
            },
          },
          // Set a FILTER variable based on a hash value
          {
            route: "/filterOFF/(.*)/?",
            data: (matches, data) => {
              let found = false;

              let filters = [];

              let filterUri = window.location.href.split("/#/filter/");
              if (filterUri.length > 0) {
                //filters = filters.trim('/');
                var re = /\/$/;
                let filterString = filterUri[1];
                filterString = filterString.replace(re, ""); // Remove trailing slash so we can split the filters on the slash and not be left with an empty item
                let filterSegs = filterString.split("/");

                let filterPairs = [];

                filterSegs.forEach((i, ix) => {
                  if ((ix + 1) % 2 == 0) {
                    // Even number - push the value into the pair from the previous segment
                    i.split("+").forEach((v) => {
                      filterPairs[ix - 1].value.push(v);
                    });
                  } else {
                    // Odd number - create a new pair at this index
                    filterPairs[ix] = {
                      key: i,
                      value: [],
                    };
                  }
                });

                filters = filterPairs;
              }

              return { FILTERING: true, FILTERS: filters };
            },
          },
          // Set a DETAILS variable based on a hash value
          {
            route: "/details/(.*)/?",
            data: (matches, data) => {
              let found = false;

              // Lookup the filter category based on slug
              found = data.entries.find(
                (entry) => entry.type == "locations" && entry.slug == matches[1]
              );

              return { DETAILS: true, DETAIL: found };
            },
          },
          // Set a DETAILS variable based on a hash value
          {
            route: "/sort/(.*)/?",
            data: (matches, data) => {
              return { SORT: matches[1] };
            },
          },
          // Set a TEMPLATE variable based on a hash value
          {
            route: "#/template/(.*)/?",
            data: (matches, data) => {
              let found = false;
              let home = false;

              found = data.entries.find((entry) => entry.uri == matches[1]);
              home = data.entries.find((entry) => entry.uri == "/");

              return { TEMPLATE: matches[1] };
            },
          },
        ]}
      >
        <Data require={["HOME"]}>{() => <Layout />}</Data>
      </DataRouter>
    );
  }
}

export default Router;
