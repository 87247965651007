import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Link from '../_core/components/Link';
import Html from '../_core/components/Html';
import Media from '../_core/components/Media';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];
		let bodyText = entry.text || '';

		let isHome = entry.uri == '/';
	
		return (
	
				<Template modifiers={[entry.style,entry.type,entry.template]} setTitle={false} title={entry.title}>

				<FadeIn duration={0.4} delay={0.1} key={entry.id}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home'} />);
	                }else{
	                	return (<SiteModifier auto demodifier={'home'} />);
	                }

	              })()}

				{/* Template */}
	

	              {/* Title */}
					<div className="Template-head">
						<div className="Container">
							<Content>
								<h1>{ entry.title }</h1>
							</Content>
						</div>
					</div>

					<div className="Template-intro">
						<div className="Container">
						<Content>
							<dl>
								<li>
									<dt>Location</dt>
									<dd><p>{entry.district}, {entry.city}</p></dd>
								</li>
								<li>
									<dt>Screen size</dt>
									<dd><Html html={entry.screenSize} /></dd>
								</li>
								<li>
									<dt>Screening time</dt>
									<dd><p>{entry.screeningStart.toFixed(2)} — {entry.screeningEnd.toFixed(2)}</p></dd>
								</li>
								<li>
									<dt>Daily Reach</dt>
									<dd>{ (new Intl.NumberFormat().format(entry.dailyReach))}</dd>
								</li>
							</dl>
						</Content>
						</div>
					</div>

					{/* Media */}
					<Asset id={entry.screenVideo} render={(asset)=>{

						return (

							<div className={"Template-media Template-media--landscape"}>
								<div className="Container">
								<div className="Item">
									<div className="Item-media">
										<Media id={entry.screenVideo} fluid={false} />
									</div>
									
								</div>
								</div>
							</div>

						)

					}} />
					<Asset id={entry.thumbnail} render={(asset)=>{

						return (

							<div className={"Template-media Template-media--"+asset.orientation}>
								<div className="Container">
								<div className="Item">
									<div className="Item-media">
										<Media id={entry.thumbnail} fluid={false} />
									</div>
									<div classNam="Item-body">
										<Content>
											<dl>
												<li>
													<dt>Screen</dt>
													<dd><p>{entry.title}</p></dd>
												</li>
												<li>
													<dt>Location</dt>
													<dd><p>{entry.district}, {entry.city}</p></dd>
												</li>
											</dl>
										</Content>
									</div>
								</div>
								</div>
							</div>

						)

					}} />
					

					<div className="Template-body">
						<div className="Container">
							<div data-grid-of="1" data-desktop-grid-of="2">
								<div><Project.Blocks blocks={[
                                      {
                                        type:'map',
                                        longitude: entry.longitude,
                                        latitude : entry.latitude,
                                        angle:entry.screenDirection,
                                        style: 'default',
                                        size : 'full'
                                      }

                                     
                                  ]} modifiers={['flush']} entry={entry}/>
                                </div>
                                <div><Project.Blocks blocks={[
                                                     
                                      {
                                        type:'text',
                                        text: entry.details.map((detail)=>(`<h3>${detail.heading}</h3>${detail.text}`)).join('') + (bodyText),
                                        style: 'default',
                                        size : 'half'
                                      }

                                  ]} modifiers={['flush']} entry={entry}/>
                                </div>
							</div>
						</div>
					</div>

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[...entry.blocks]} entry={entry}/>
						</div>

					</div>
					
				</FadeIn>
				</Template>
		)

	}
}

export default Default;
