import React, { Component } from "react";

import * as THREE from "three";

import DummyScene from "../../THREE/Scene";
import NoiseFloor from "../../THREE/NoiseFloor";
import ThreeJs from "../../THREE/ThreeJs";
import ImagePlane from "../../_core/THREE/ImagePlane";

import Rainbow from "rainbowvis.js";

import SimplexNoise from "simplex-noise";
// 10
//const simplex = new SimplexNoise(31);

var seed = Math.ceil(Math.random() * 1000);
//console.log("SEED", seed);
// 647, 513, 586,945,120,202,168,933
seed = 945;
const simplex = new SimplexNoise(seed); // 33, 2,3,6,7,12,78
const simplex2 = new SimplexNoise(21); // 14 , 20, 21, 12, 7
const SIMPLEX_SCALE = 0.018;

const SMALL_ASPECT = 1.4;

function lerp(v0, v1, t) {
    return v0 * (1 - t) + v1 * t;
}

let degToRad = function (deg) {
    return (deg * Math.PI) / 180;
};

let Color = function (hexOrObject) {
    var obj;
    if (hexOrObject instanceof Object) {
        obj = hexOrObject;
    } else {
        obj = LinearColorInterpolator.convertHexToRgb(hexOrObject);
    }
    this.r = obj.r;
    this.g = obj.g;
    this.b = obj.b;
};
Color.prototype.asRgbCss = function () {
    return "rgb(" + this.r + ", " + this.g + ", " + this.b + ")";
};

let LinearColorInterpolator = {
    // convert 6-digit hex to rgb components;
    // accepts with or without hash ("335577" or "#335577")
    convertHexToRgb: function (hex) {
        let match = hex.replace(/#/, "").match(/.{1,2}/g);
        return new Color({
            r: parseInt(match[0], 16),
            g: parseInt(match[1], 16),
            b: parseInt(match[2], 16),
        });
    },
    // left and right are colors that you're aiming to find
    // a color between. Percentage (0-100) indicates the ratio
    // of right to left. Higher percentage means more right,
    // lower means more left.
    findColorBetween: function (left, right, percentage) {
        let newColor = {};
        let components = ["r", "g", "b"];
        for (var i = 0; i < components.length; i++) {
            let c = components[i];
            newColor[c] = Math.round(
                left[c] + ((right[c] - left[c]) * percentage) / 100
            );
        }
        return new Color(newColor);
    },
};

let COLS = 100;
let ROWS = COLS;
let NUM = ROWS * COLS;

let INTROPOS = [];
let STARTPOS = [];
let ENDPOS = [];

let SPECTRUM;

let SPECTRUMCOLOR = [];
let STARTCOLOR = [];
let ENDCOLOR = [];

let GEOMETRY = {};
let PAUSED = false;

let ANIMATING = 1;
let ANIMATION = false;
let RESET = true;

class App extends Component {
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.img != this.props.img) {
            this.loadImg(nextProps.img);
        }
    };
    init = ({ camera, renderer }) => {
        const scene = new THREE.Scene();
        const texLoader = new THREE.TextureLoader();

        let cols = COLS;
        let rows = ROWS;
        let num = NUM;

        // Ensure using power of 2 number
        num = cols * cols;

        var colorStops = [
            new Color({ r: 48, g: 15, b: 118 }),
            new Color({ r: 48, g: 15, b: 118 }),
        ];
        let geometry = new THREE.Geometry();

        let row = 0;
        let col = 0;
        let ix = 0;

        let cellSize = 12;
        let cellSize2 = cellSize * 0.5;

        let explodeX = 60;
        let explodeY = 60;
        let explodeZ = 800;
        let explodeZ2 = 500;

        let spectrum = new Rainbow();
        //spectrum.setNumberRange(1, num);
        let randKey = Math.floor(Math.random() * 2);
        let randStart = Math.floor(Math.random() * 16777215).toString(16); // 16777215 = ffffff in decimal - www.paulirish.com/2009/random-hex-color-code-snippets/

        randStart = ["55d6aa", "02FF87", "FF4400", "FF7D00", "55D6AA"][0];

        //spectrum.setSpectrum('55D6AA','5A36ED', '9342D0', '78D1F6' );

        spectrum.setSpectrum(randStart, "5A36ED", "9342D0", "78D1F6");

        spectrum.setSpectrum("15023C", "8028D2", "300F76", "00FFC4");

        SPECTRUM = spectrum;

        [...Array(rows)].forEach((row, rix) => {
            [...Array(cols)].forEach((col, cix) => {
                let progress = ix / num;
                var vertex = new THREE.Vector3();

                /*STARTPOS[ix] = {
                    x : cix * explodeX - (cols * (explodeX /2 )),
                    y : rix * explodeY - (rows * (explodeY /2 )),
                    z : Math.sin(cix * explodeZ) * explodeZ +  Math.sin(rix * explodeZ) * explodeZ
                }*/

                let noiseX = simplex.noise2D(
                    rix * SIMPLEX_SCALE,
                    rows * SIMPLEX_SCALE
                );
                let noiseY = simplex.noise2D(
                    rix * SIMPLEX_SCALE,
                    cix * SIMPLEX_SCALE
                );
                let noiseZ = simplex.noise2D(
                    cix * SIMPLEX_SCALE,
                    rix * SIMPLEX_SCALE
                );

                let noiseZ2 = simplex2.noise2D(cix * 0.015, rix * 0.015);

                let rowProg = rix / rows;

                //console.log(noise);

                STARTPOS[ix] = {
                    x: cix * explodeX - cols * (explodeX / 2),
                    y: noiseZ * explodeZ - explodeZ * 0.2,
                    z: rix * explodeY - rows * (explodeY / 2) + 3000,
                };

                INTROPOS[ix] = {
                    x: cix * explodeX - cols * (explodeX / 2),
                    y: noiseZ2 * explodeZ2 - explodeZ2 * 0.2,
                    z: rix * explodeY - rows * (explodeY / 2) + 3000,
                };

                ENDPOS[ix] = {
                    x: cix * cellSize - cols * cellSize2,
                    y: rix * cellSize - rows * cellSize2,
                    z: 0,
                };
                vertex.x = STARTPOS[ix].x;
                vertex.y = STARTPOS[ix].y;
                vertex.z = STARTPOS[ix].z;

                let vertexColor = LinearColorInterpolator.findColorBetween(
                    colorStops[0],
                    colorStops[1],
                    (ix / num) * 100
                );

                let spectrumColor = spectrum.colourAt((cix / cols) * 100);
                //console.log(spectrumColor);
                let spectrumColorRGB =
                    LinearColorInterpolator.convertHexToRgb(spectrumColor);

                SPECTRUMCOLOR[ix] = {
                    r: spectrumColorRGB.r / 255,
                    g: spectrumColorRGB.g / 255,
                    b: spectrumColorRGB.b / 255,
                };

                STARTCOLOR[ix] = ENDCOLOR[ix] = {
                    r: vertexColor.r / 255,
                    g: vertexColor.g / 255,
                    b: vertexColor.b / 255,
                };

                // Store userdata in the vertex
                vertex.col = cix;
                vertex.row = rix;

                geometry.colors.push(
                    new THREE.Color(
                        SPECTRUMCOLOR[ix].r,
                        SPECTRUMCOLOR[ix].g,
                        SPECTRUMCOLOR[ix].b
                    )
                );
                geometry.vertices.push(vertex);

                ix++;
            });
        });

        geometry.colorsNeedUpdate = true;

        /*
            THREE.NoBlending
THREE.NormalBlending
THREE.AdditiveBlending
THREE.SubtractiveBlending
THREE.MultiplyBlending
THREE.CustomBlending
        */

        var sprite = texLoader.load("/ui/img/map/sprite.png");
        var material = new THREE.PointsMaterial({
            size: 10,
            map: sprite,
            vertexColors: THREE.VertexColors,
            transparent: true,
            alphaTest: 0.1,
            opacity: 1,
            side: THREE.DoubleSide,
            // depthTest:false,
            blending: THREE.AdditiveBlending,
        });

        let particles = new THREE.Points(geometry, material);

        scene.add(particles);

        GEOMETRY = geometry;

        this.loadImg(this.props.img);

        particles.rotation.x = degToRad(95);
        particles.rotation.y = degToRad(190);

        particles.position.set(0, 0, 0);
        particles.rotation.set(0, 0, 0);

        camera.position.z = 6000;

        camera.position.y = 500;
        camera.lookAt(particles.position.clone());

        let clock = new THREE.Clock();
        clock.start();

        /*if (this.props.playintro)
            this.playintro({ scene, particles, geometry, material, clock });*/

        return { scene, particles, geometry, material, clock };
    };

    loadImg = (src) => {
        let cols = COLS;
        let rows = ROWS;
        let num = NUM;

        let canvas = document.getElementById("sketch");
        let ctx = canvas.getContext && canvas.getContext("2d");

        let height = rows;
        canvas.height = height;

        let width = cols;
        canvas.width = width;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
            ctx.drawImage(img, 0, 0, width, height); // Or at whatever offset you like

            let data = ctx.getImageData(0, 0, width, height);
            let length = data.data.length;
            let count = 0;
            let i = 0;

            let values = [];
            //  + 4 for r,g,b, and a
            while ((i += 4) <= length) {
                ++count;
                let r = data.data[i];
                let g = data.data[i + 1];
                let b = data.data[i + 2];
                let a = data.data[i + 3];
                let average = (r + g + b / (255 * 3)) / 255;
                values.unshift({
                    r: r,
                    g: g,
                    b: b,
                    a: a,
                    average: average,
                });
            }

            values.forEach((v, ix) => {
                let c = STARTCOLOR[ix];
                let m = 1 - v.average;

                ENDCOLOR[ix] = {
                    r: c.r * m,
                    g: c.g * m,
                    b: c.b * m,
                };

                if (m < 0.2) {
                    ENDCOLOR[ix] = {
                        r: 6 / 255,
                        g: 6 / 255,
                        b: 38 / 255,
                    };
                }
                //geometry.colors[ix].setRGB(c.r * m,c.g * m,c.b * m);
                //geometry.vertices[ix].z = 0;
            });

            GEOMETRY.colorsNeedUpdate = true;
            GEOMETRY.verticesNeedUpdate = true;
        };
        img.src = src;
    };
    playintro = ({ scene, camera, particles, geometry, material, clock }) => {
        let progress = { val: 0 };
        let angle = Math.random();
        let _this = this;

        let randKey = Math.floor(Math.random() * 2);
        let startangle = [
            {
                start: degToRad(90),
                end: degToRad(45),
                y: degToRad(-5),
            },
            {
                start: degToRad(-90),
                end: degToRad(-45),
                y: degToRad(2),
            },
        ][0];
        //window.TweenMax.to(particles.position,3,{y:0});
        window.TweenMax.to(progress, 2.5, {
            val: 1,
            easeoff: window.Power4.easeInOut,
            onUpdate: function () {
                let amt = progress.val;

                material.size = 70 * (2 - amt);
                material.size = 80;

                material.opacity = 0.5 * amt;

                if (amt <= 1) {
                    geometry.vertices.forEach((v, ix) => {
                        let start = STARTPOS[ix];
                        let end = INTROPOS[ix];

                        let colstart = SPECTRUMCOLOR[ix];
                        let colend =
                            SPECTRUMCOLOR[SPECTRUMCOLOR.length - 1 - ix];

                        //geometry.colors[ix].r = lerp(colend.r,colstart.r,amt);
                        //geometry.colors[ix].g = lerp(colend.g,colstart.g,amt);
                        // geometry.colors[ix].b = lerp(colend.b,colstart.b,amt);

                        var adjust = (start.x + start.y) * 0.01;

                        //v.z = lerp(start.z,start.z - 4500, amt);
                        v.y = start.y;
                    });

                    particles.position.z = lerp(500, -4500, amt);
                    particles.position.y = 600;
                    particles.position.x = 150;
                    //particles.rotation.x = lerp(degToRad(20),degToRad(0),amt);
                    //particles.rotation.y = lerp(degToRad(30),startangle.y,amt);
                    particles.rotation.z = lerp(
                        degToRad(0),
                        degToRad(-55),
                        amt
                    );

                    geometry.verticesNeedUpdate = true;
                    geometry.colorsNeedUpdate = true;

                    return;
                }
            },
            onComplete: function () {
                let out = { val: 0 };

                //window.TweenMax.to(particles.position,2,{x:0,y:0,z:0});
                window.TweenMax.to(out, 1.2, {
                    val: 1,
                    onUpdate: function () {
                        let amt = out.val;

                        material.size = 80 * (1 - amt);

                        if (amt <= 1) {
                            geometry.vertices.forEach((v, ix) => {
                                let start = INTROPOS[ix];
                                let end = ENDPOS[ix];

                                let colstart = SPECTRUMCOLOR[ix];
                                let colend = {
                                    r: 6 / 255,
                                    g: 6 / 255,
                                    b: 38 / 255,
                                };

                                geometry.colors[ix].r = lerp(
                                    colstart.r,
                                    colend.r,
                                    amt
                                );
                                geometry.colors[ix].g = lerp(
                                    colstart.g,
                                    colend.g,
                                    amt
                                );
                                geometry.colors[ix].b = lerp(
                                    colstart.b,
                                    colend.b,
                                    amt
                                );
                            });

                            geometry.verticesNeedUpdate = true;
                            geometry.colorsNeedUpdate = true;

                            return;
                        }
                    },
                    onComplete: function () {
                        ANIMATING = false;
                        _this.props.onIntroComplete();
                    },
                });
            },
        });
    };
    resetDots = ({ scene, camera, particles, geometry, material, clock }) => {
        ANIMATING = true;
        let out = { val: 0 };

        let CURSIZE = material.size;
        let CUROPACITY = material.opacity;
        let CURPOS = geometry.vertices.map((v) => ({ x: v.x, y: v.y, z: v.z }));

        //window.TweenMax.to(particles.position,2,{x:0,y:0,z:0});
        ANIMATION = window.TweenMax.to(out, 0.5, {
            val: 1,
            onUpdate: function () {
                let amt = out.val;

                material.size = lerp(CURSIZE, 0, amt);
                material.opacity = lerp(CUROPACITY, 0, amt);
            },
            onComplete: function () {
                ANIMATING = false;
                RESET = true;
            },
        });
    };
    loopabstract = ({
        scene,
        camera,
        particles,
        geometry,
        material,
        clock,
    }) => {
        //let amt = (this.props.mouse.middle.x) + 0.5;
        //let progress = this.props.mouse.x / this.props.mouse.w;

        let amt = this.props.progress;

        amt = 0.42;

        if (!amt) return;

        let progress = amt;

        //particles.rotation.y += 0.001;

        if (amt > 1) amt = 1;
        if (amt < 0) amt = 0;

        amt *= 2;

        let rampIn = 0.3;
        let rampOut = 0.5;

        let rampAmt = 1;
        let rampMeasure = amt / 2;

        if (rampMeasure < rampIn) {
            rampAmt = rampMeasure / rampIn;
        }

        if (rampMeasure > rampOut) {
            rampAmt = 1 - (rampMeasure - rampOut) / (1 - rampOut);
        }

        //camera.rotation.x = this.props.mouse.middle.x *  degToRad(-1);
        //camera.rotation.y = this.props.mouse.middle.y * degToRad(-1);

        /*
        particles.position.z = ((1 - 0) * 1000);
        particles.position.x = ((0) * 500);
        particles.rotation.x = ((1.1 - 0) * degToRad(100));
        particles.rotation.y = ((1 - 0) * degToRad(30));
        */

        if (amt < 1) {
            //particles.rotation.z = lerp(degToRad(90),0,amt);
        } else {
            //particles.rotation.z = 0;
        }

        //particles.rotation.x = 0;
        //particles.rotation.y = 0;

        //particles.rotation.y += this.props.mouse.middle.x * degToRad(1);

        particles.rotation.z =
            this.props.dir == "left" ? degToRad(45) : degToRad(-45);

        material.size = 70 * rampAmt;
        material.opacity = rampAmt * 2;

        /*if(amt < 0.5){
                material.opacity = (amt * 2);
                material.size = 70 * (amt * 2)
            }

            if(amt > 1.5){
                material.opacity = ((amt - 0.5) / 2);
                material.size = 70 * ((amt - 1.5));
            }*/

        geometry.vertices.forEach((v, ix) => {
            let start = STARTPOS[ix];
            let intro = INTROPOS[ix];
            let end = ENDPOS[ix];

            // Whether to position left, right, center;
            let nudge = 700;
            if (this.props.dir == "left") nudge = -500;

            let colstart = SPECTRUMCOLOR[ix];
            let colend = ENDCOLOR[ix];

            /*if(amt > 1){
                return;
                v.x = end.x + nudge;
                v.z = lerp(end.z,-100,amt - 1);;
                v.y = end.y;

                geometry.colors[ix].r = colend.r;
                geometry.colors[ix].g = colend.g;
                geometry.colors[ix].b = colend.b;

                return;
            }*/

            //v.x = lerp(start.x,end.x + nudge,amt);
            //v.z = lerp(start.z,end.z,amt);

            var adjust = (v.x + v.z) * 0.01;

            let y =
                intro.y +
                Math.sin(adjust + clock.getElapsedTime()) * (10 + amt * 20);
            v.y = y; //lerp(y,end.y,amt);

            let colAmt = amt <= 0.7 ? amt : 0.7;
            geometry.colors[ix].r = lerp(colstart.r, colend.r, colAmt);
            geometry.colors[ix].g = lerp(colstart.g, colend.g, colAmt);
            geometry.colors[ix].b = lerp(colstart.b, colend.b, colAmt);
        });
        geometry.verticesNeedUpdate = true;
        geometry.colorsNeedUpdate = true;
    };

    loop = ({ scene, camera, particles, geometry, material, clock }) => {
        //let amt = (this.props.mouse.middle.x) + 0.5;
        //let progress = this.props.mouse.x / this.props.mouse.w;

        let amt = this.props.progress;
        amt = 0.42;

        let progress = amt;

        // Get into position by 60% progress
        let posProgress = progress / 0.6;
        if (posProgress > 1) posProgress = 1;

        //particles.rotation.y += 0.001;

        if (amt > 1) amt = 1;
        if (amt < 0) amt = 0;

        //camera.rotation.x = this.props.mouse.middle.x *  degToRad(-1);
        //camera.rotation.y = this.props.mouse.middle.y * degToRad(-1);

        let nudge = window.innerWidth * 0.3;

        // Mobile, remove nudge
        if (window.innerWidth < 700) nudge = 0;

        // Square-ish aspect, zoom out a bit
        if (window.innerWidth / window.innerHeight <= SMALL_ASPECT)
            camera.position.z = 1600;

        // Direction left, reverse nudge
        if (this.props.dir == "left") nudge = -(nudge * 0.8);

        particles.position.z = (1 - posProgress) * 1000;
        particles.position.x = posProgress * nudge;
        particles.rotation.x = degToRad(-12.5);

        particles.rotation.y = degToRad(-2);

        particles.rotation.z = degToRad(5);
        particles.position.y = -1200;

        material.size = 10 + (1 - posProgress) * 100;
        let sizeAdjust = Math.sin(clock.getElapsedTime() * 0.5) * 20;
        material.size = Math.min(25 + sizeAdjust, 30);

        if (progress > 0.8) {
            material.size -= (1 - (1 - progress) / 0.2) * 10;
        }
        material.opacity = 0.3;
        geometry.vertices.forEach((v, ix) => {
            let start = STARTPOS[ix];
            let end = ENDPOS[ix];

            let colstart = SPECTRUMCOLOR[ix];
            let colend = ENDCOLOR[ix];

            v.x = lerp(start.x, end.x, posProgress);
            let baseYPos = lerp(start.y, end.y, posProgress);

            var adjust = (v.x + v.y) * 0.01;
            let movement = Math.sin(adjust + clock.getElapsedTime() * 1.5) * 13;
            v.y = baseYPos + movement;

            let colProgress = v.row / ROWS;

            geometry.colors[ix].r = colstart.r;
            geometry.colors[ix].g = colstart.g;
            geometry.colors[ix].b = colstart.b;
        });
        geometry.verticesNeedUpdate = true;
        geometry.colorsNeedUpdate = true;
    };

    render() {
        return <ThreeJs onInit={this.init} onLoop={this.loop} />;
    }
}

export default App;
