import { useEffect, useRef } from "react";
import Link from "../../_core/components/Link";

export default (props) => {
	let muted = props.hasOwnProperty("muted") ? props.muted : false;

	let $player = useRef();

	useEffect(() => {
		if ($player.current) {
			if (props.play) {
				$player.current.currentTime = 0;
				$player.current.play();
			} else {
				//$player.current.currentTime = 0;
				$player.current.pause();
			}
		}
	}, [props.src, $player, props.play]);

	if (!props.src) return null;
	return (
		<div className="Player">
			<div className="Player-media">
				<video
					ref={$player}
					autoPlay
					preload={"true"}
					playsInline
					muted={muted}
					controls={true}
					src={props.src}
				/>
			</div>
		</div>
	);
};
