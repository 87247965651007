import React, { Component } from "react";

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from "../_core/components/PageElement";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";

import Link from "../_core/components/Link";
import SiteModifier from "../_core/components/SiteModifier";
import FadeIn from "../_core/components/FadeIn";

import Media from "../_core/components/Media";

// Models
import { Asset, Entry, Data, SetData } from "../_core/models";

// Project
import * as Project from "../components";

class Default extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	/*

		RENDER

	*/

	render() {
		return (
			<Data>
				{({ entries, HOME }) => {
					const formats = entries.filter((e) => e.type === "formats");

					return (
						<Template
							modifiers={["homepage", "blocks"]}
							title={"Vivid City"}
						>
							<FadeIn duration={0.4} delay={0.1} key={"homepage"}>
								{/* Reset styles */}
								<SiteModifier auto demodifier={"scrolled"} />
								<SiteModifier auto demodifier={"home"} />

								{/* Blocks */}
								<div className="Template-blocks">
									<div className="Container">
										<Project.Blocks
											blocks={[
												{
													type: "text",
													text: `<h1>Be<br />Unmissable.</h1><h3>Global out of home specialists</h3><h2>Advertising for humans,<br />wherever they are.</h2>`,
													size: "full",
												},
												{
													type: "reel",
													src: `/ui/video/reel-230106.mp4`,
													expandSrc: `/ui/video/reel-230106.mp4`,
												},
												{
													type: "text",
													text: `
										<h1>Our Solutions</h1>
										<h3>Advertise Anywhere</h3>
										<h2>Multi-channel,<br />
										multi-national, multi-media.</h2>`,
													size: "full",
													links: [
														{
															customLink:
																"/#/view/screens/",
															callToAction:
																"Find Screens",
														},
													],
												},
												{
													type: "swiper",

													breakpoints: {
														0: {
															slidesPerView: 1.5,
														},
														640: {
															slidesPerView: 3.5,
														},
													},
													items: formats.map((f) => (
														<div className="Item Item--swiper">
															<Link to={f.uri}>
																<div className="Item-media">
																	<Media
																		id={
																			f
																				.thumbnail[0]
																		}
																		ratio={
																			9 /
																			12
																		}
																		fluid={
																			false
																		}
																		overrides={{
																			inlineVideo: true,
																		}}
																	/>
																</div>
															</Link>
															<div className="Item-body">
																<Content
																	html={`<h3>${f.title}</h3>`}
																/>
															</div>
															<div className="Item-icon">
																<Project.Icon
																	icon={
																		f.icon
																	}
																/>
															</div>
														</div>
													)),
												},
												{
													type: "text",
													text: `
										<h1>Case Studies</h1>
										
										<h2>What we've done.<br />
										Big impact for big brands.</h2>`,
													size: "full",
													links: [
														{
															customLink:
																"/projects/",
															callToAction:
																"See all projects",
														},
													],
												},
												{
													type: "swiper",
													breakpoints: {
														0: {
															slidesPerView: 1.1,
														},
														640: {
															slidesPerView: 1.5,
														},
													},
													items: HOME.caseStudies.map(
														(i) => (
															<div className="Item Item--swiper">
																<Link
																	to={
																		i.linkTo
																	}
																>
																	<div className="Item-media">
																		<Media
																			id={
																				i
																					.image[0]
																			}
																			ratio={
																				800 /
																				488
																			}
																			fluid={
																				false
																			}
																			overrides={{
																				inlineVideo: true,
																			}}
																		/>
																	</div>
																</Link>
																<div className="Item-body">
																	<Content
																		html={`<h3>${i.heading}</h3>`}
																	/>
																</div>
															</div>
														)
													),
												},
												{
													type: "logos",
													text: `
										<h3>Selected Clients</h3>`,
													size: "full",
													logos: [],
												},
												{
													type: "text",
													text: `
										<h1>Our Locations</h1>
										
										<h2>Whoever, wherever.<br />
										We can reach them</h2>`,
													size: "full",
													links: [
														{
															customLink:
																"/#/view/screens/",
															callToAction:
																"Find Screens",
															style: "alt",
															icon: "search",
														},
														{
															customLink:
																"/#/view/pricing/",
															callToAction:
																"Pricing",
															icon: "money",
														},
													],
												},

												{
													type: "dotmap",
												},
												{
													type: "section",
													text: `
										<h1>What we do</h1>
										<h3>Our Expertise</h3>
										<h2>From media planning to creative.
If you need to reach an audience, you’re in the right place.</h2>`,
													size: "full",
													style: "numberList2",
													itemStyle: "numberList2",
													itemSize: "onethird",
													links: [
														{
															customLink:
																"/who-we-are/",
															text: "More about us",
														},
														{
															customLink:
																"/#/view/pricing/",
															text: "Pricing",
															icon: "money",
														},
													],

													items: [
														{
															text: `
												<h4>Plan & Buy</h4>
												<p>We work with clients and agencies to create, plan and buy international 360° digital Out-of-Home advertising campaigns that join up large-scale messaging, engaging consumer activations and online social activity.</p>`,
														},
														{
															text: `
												<h4>Create</h4>
												<p>Our experienced creative team craft brand experiences that resonate with audiences cohesively across mega digital out-of-home screens, physical and online platforms.</p>`,
														},
														{
															text: `
												<h4>Execute</h4>
												<p>We coordinate between technology providers and government organisations to produce seamless, large scale brand experiences that get your brand face-to-face with audiences.</p>`,
														},
													],
												},
												{
													type: "background",
													style: "alt",
												},
												{
													type: "calculator",
												},
												{
													type: "background",
													style: "none",
												},
												{
													type: "contacts",
												},
											]}
											modifiers={[]}
										/>
									</div>
								</div>
							</FadeIn>
						</Template>
					);
				}}
			</Data>
		);
	}
}

export default Default;
