import React, { Component } from "react";

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from "../_core/components/PageElement";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";
import SiteModifier from "../_core/components/SiteModifier";
import FadeIn from "../_core/components/FadeIn";
import Link from "../_core/components/Link";
import Toggle from "../_core/components/Toggle";
import Icon from "../_core/components/Icon";

// Models
import { Asset, Entry, Data, SetData } from "../_core/models";

// Project
import * as Project from "../components";

class Default extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	/*

		RENDER

	*/

	render() {
		let { entry } = this.props;
		if (!entry) return null;

		let blocks = entry.blocks || [];

		let isHome = entry.uri == "/";

		return (
			<Template
				modifiers={[entry.style, entry.type, entry.template]}
				setTitle={false}
				title={entry.title}
			>
				<FadeIn duration={0.4} delay={0.1} key={entry.id}>
					{/* Reset styles */}
					<SiteModifier auto demodifier={"scrolled"} />
					{(() => {
						if (isHome) {
							return <SiteModifier auto modifier={"home"} />;
						} else {
							return <SiteModifier auto demodifier={"home"} />;
						}
					})()}

					{/* Template */}

					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<Content>
								<h1>{entry.pageTitle || entry.title}</h1>
							</Content>
						</div>
					</div>

					<div className="Template-intro">
						<div className="Container">
							<Content html={entry.text} />
						</div>
					</div>

					<Data>
						{({ entries, menus, FILTERS }) => {
							let source = entry.source;

							let items = [];
							if (source == "childPages")
								items = entries.filter(
									(e) => e.parent == entry.id
								);
							if (source == "relatedPages")
								items = entry.related.map((id) =>
									entries.find((e) => e.id == id)
								);

							let filterLocations = entries.filter(
								(e) => e.type == "regions"
							);

							let filterFormats = entries.filter(
								(e) => e.type == "formats"
							);

							return (
								<Project.Filter
									items={items}
									key={window.location.href}
									filters={FILTERS}
									formats={filterFormats}
									locations={filterLocations}
									results={(results) => {
										let items = results.map((i) => {
											return {
												linkTo: [i.id],
												image: [],
												inheritContentFromLink: true,
												text: `<p>Location: ${i.city}`,
												callToAction: "View Details",
											};
										});

										let blocks = [];
										blocks.push({
											type: "default",
											items: items,
											itemSize: "half",
											itemStyle: "screens",
										});

										return (
											<div className="Template-blocks">
												<div className="Container">
													<Project.Blocks
														blocks={[...blocks]}
														entry={entry}
														modifiers={[]}
														key={JSON.stringify(
															results
														)}
													/>
												</div>
											</div>
										);
									}}
								/>
							);
						}}
					</Data>
				</FadeIn>
			</Template>
		);
	}
}

export default Default;
