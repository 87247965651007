import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Project
import * as Project from "../../components";

const CheckBox = ({
  name,
  value,
  label,
  icon,
  checked = false,
  click = () => {},
  variant,
}) => {
  return (
    <div
      onClick={() => click()}
      className="Item--checkbox"
      data-variant={variant ? variant : null}
      data-checked={checked ? "checked" : null}
    >
      <div className="Item-check">
        <div className="Site-icon Site-icon--small" data-icon="check" />
      </div>
      <div className="Item-label">{label}</div>
      {(() => {
        if (icon) {
          return (
            <div className="Item-icon">
              <Project.Icon icon={icon} />
            </div>
          );
        }
      })()}
    </div>
  );
};

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const SuperSelect = ({
  name,
  value,
  label,
  icon,
  checked = false,
  toggleAll = () => {},
  options = [],
}) => {
  let el = useRef();
  let iconRef = useRef();
  let bodyRef = useRef();
  let [open, setOpen] = useState(false);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(el, () => setOpen(false));

  return (
    <div
      ref={el}
      className="Item--select"
      onClick={(event) => {
        // Propagate toggleAll function so long as click doesnt originate from drop menu or drop icon
        if (bodyRef.current.contains(event.target)) {
          return;
        }
        if (iconRef.current.contains(event.target)) {
          return;
        }
        if (open) {
          setOpen(false);
        } else {
          toggleAll();
        }
      }}
      data-checked={checked ? "checked" : null}
      data-open={open ? "open" : null}
    >
      <div className="Item-check">
        <div className="Site-icon Site-icon--small" data-icon="check" />
      </div>
      <div className="Item-label">{label}</div>
      <div className="Item-icon" ref={iconRef} onClick={() => setOpen(!open)}>
        <Project.Icon icon={"left8"} />
      </div>

      <div className="Item-body" ref={bodyRef}>
        {options.map(({ checked, click, label, name, value, options }, oIx) => (
          <div key={`selectopt${name}_${oIx}`}>
            <CheckBox
              key={`${oIx}`}
              variant={"secondary"}
              label={label}
              name={name}
              value={value}
              click={click}
              checked={checked}
            />

            {(() => {
              if (options) {
                return (
                  <div className="Item-sub-options">
                    {options.map(
                      ({ checked, click, label, name, value }, sIx) => (
                        <CheckBox
                          key={`${oIx}${sIx}`}
                          label={label}
                          name={name}
                          value={value}
                          click={click}
                          checked={checked}
                          variant={"tertiary"}
                        />
                      )
                    )}
                  </div>
                );
              }
            })()}
          </div>
        ))}
      </div>
    </div>
  );
};

const makeFilterUrl = (filters) => {
  let baseURL = `#/view/screens/`;
  let URLparts = [];

  Object.keys(filters).forEach((k) => {
    let selected = filters[k];
    let segments = [];
    if (selected.length) {
      segments = k + "/" + selected.join("+");

      URLparts.push(segments);
    }
  });

  let filterPath = URLparts.length ? `filter/${URLparts.join("/")}/` : "";
  return baseURL + filterPath;
};

const slugify = (text) => {
  return text.toLowerCase().replace(/\s/gi, "-");
};

const filtersFromUrl = (filters) => {
  let out = {};

  Object.keys(filters).forEach((k) => {
    let active = [];
    let re = new RegExp(`filter/(.*)${k}/([^/]+)`, "gi");
    let segments = window.location.href.match(re);

    if (segments && segments.length) {
      let parts = segments[0].split(`${k}/`);
      let active = [];
      if (parts.length > 1) {
        active = parts[1].split("+");
      }
      out[k] = active;
    } else {
      out[k] = [];
    }
  });

  return out;
};

const applyFilters = ({ items, filters, formats, locations }) => {
  let filtered = items;
  let defaultFormat = formats.find((f) => f.defaultFormat === 1);

  // Expand the array of slugs into objects
  let expandFormats = filters.format.map((slug) => {
    return formats.find((f) => f.slug === slug);
  });

  const cityInRegion = (citySlug, regionSlug) => {
    // Limit the locations to search by the location whose slugified region matches the regionSlug
    let found = false;
    let region = locations.find((l) => l.slug === regionSlug);

    if (region) {
      let areas = region.regions;
      areas.forEach((a) => {
        let cityInArea = a.cities.find((c) => citySlug === slugify(c.city));
        if (cityInArea) {
          found = true;
        }
      });
    }

    return found;
  };

  const cityInArea = (citySlug, areaSlug) => {
    // Limit the locations to search by the location whose slugified region matches the regionSlug
    let found = false;
    let area;

    // Find te region for this area and store the area so we can search it
    let region = locations.find((l) => {
      let foundArea = l.regions.find((r) => slugify(r.area) === areaSlug);
      // Store what we found so we dont need to look it up again
      if (foundArea) area = foundArea;
      return foundArea;
    });

    if (area) {
      let cityInArea = area.cities.find((c) => citySlug === slugify(c.city));
      if (cityInArea) {
        found = true;
      }
    }

    return found;
  };

  // Filter by format

  if (filters.format.length) {
    filtered = filtered.filter((i) => {
      if (i.format) {
        // Check if it can be returned for the default (i.e treat lack of format value as a match )
        let isDefaultFormat =
          !i.format.length || i.format.indexOf(defaultFormat.id) !== -1;
        let defaultFormatIsActive =
          filters.format.indexOf(defaultFormat.slug) !== -1;

        if (isDefaultFormat && defaultFormatIsActive) return true;

        // Now check if it matches a different active format
        let isMatch = false;
        if (i.format.length) {
          expandFormats.forEach((f) => {
            if (i.format.indexOf(f.id) !== -1) isMatch = true;
          });
        }

        return isMatch;
      }
    });
  }

  // Filter by location
  if (filters.city.length || filters.area.length || filters.region.length) {
    filtered = filtered.filter((i) => {
      // Check if this item is allowed in all locations
      if (i.allLocations) return true;

      let isMatchRegion = false;
      let isMatchArea = false;
      let isMatchCity = false;

      // Check region
      if (filters.region.length) {
        if (i.city) {
          filters.region.forEach((regionSlug) => {
            if (cityInRegion(slugify(i.city), regionSlug)) isMatchRegion = true;
          });
        }
      }

      // Filter by area
      if (filters.area.length) {
        if (i.city) {
          filters.area.forEach((areaSlug) => {
            if (cityInArea(slugify(i.city), areaSlug)) isMatchArea = true;
          });
        }
      }

      // Filter by city
      if (filters.city.length) {
        if (i.city) {
          filters.city.forEach((citySlug) => {
            if (slugify(i.city) === citySlug) isMatchCity = true;
          });
        }
      }

      return isMatchRegion || isMatchArea || isMatchCity;
    });
  }

  return filtered;
};

const Filter = ({ items, results, formats, locations }) => {
  const filters = useRef(
    filtersFromUrl({ format: [], region: [], area: [], city: [] })
  );
  let history = useHistory();

  let filteredItems = applyFilters({
    items: [...items],
    filters: filters.current,
    formats,
    locations,
  });

  const click = ({ name, value }) => {
    // copy the current selection
    let current = [...filters.current[name]];
    let isSelected = current.indexOf(value) !== -1;

    if (isSelected) {
      // Remove from filter
      current.splice(current.indexOf(value), 1);
    } else {
      // Add to filter
      current.push(value);
    }

    let updated = { ...filters.current };
    updated[name] = current;

    filters.current = updated;

    let filterUrl = makeFilterUrl(filters.current);
    history.push(filterUrl);
  };

  const isSelected = ({ name, value }) => {
    let current = [...filters.current[name]];
    let isSelected = current.indexOf(value) !== -1;

    return isSelected;
  };

  const cityOptionsForArea = (cities) => {
    if (cities.length <= 1) return [];

    return cities.map((c) => {
      return {
        name: "city",
        value: slugify(c.city),
        label: c.city,
        checked: isSelected({
          name: "city",
          value: slugify(c.city),
        }),
        click: () => {
          click({ name: "city", value: slugify(c.city) });
        },
      };
    });
  };

  return (
    <div>
      <div className="Template-filter-section">
        <div className="Content">
          <h4>Solutions</h4>
        </div>
        <div className="Template-filters">
          {formats.map((f) => (
            <div key={`format${f.id}`}>
              <CheckBox
                label={f.title}
                icon={f.icon}
                checked={isSelected({ name: "format", value: f.slug })}
                click={() => click({ name: "format", value: f.slug })}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="Template-filter-section">
        <div className="Content">
          <h4>Locations</h4>
        </div>
        <div className="Template-filters" data-cols="4">
          {locations.map((r) => (
            <div key={`location${r.id}`}>
              <SuperSelect
                label={r.title}
                icon={r.icon}
                checked={isSelected({ name: "region", value: r.slug })}
                toggleAll={() => click({ name: "region", value: r.slug })}
                options={r.regions.map((a) => {
                  return {
                    name: "area",
                    value: slugify(a.area),
                    label: a.area,
                    checked: isSelected({
                      name: "area",
                      value: slugify(a.area),
                    }),
                    click: () => {
                      click({ name: "area", value: slugify(a.area) });
                    },
                    options: cityOptionsForArea(a.cities),
                  };
                })}
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        <div>{results(filteredItems)}</div>
      </div>
    </div>
  );
};

export default Filter;
